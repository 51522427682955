define("additive-mi/mirage/scenarios/test/default", ["exports", "additive-mi/mirage/scenarios/organization", "additive-mi/mirage/scenarios/user"], function (_exports, _organization, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DEFAULT_ORG_ID = void 0;
  const DEFAULT_ORG_ID = _exports.DEFAULT_ORG_ID = 'test-org';
  var _default = (server, options = {}) => {
    const _options = Object.assign({
      loadOrganization: true,
      loadUser: true
    }, options);
    server.create('organization', {
      id: DEFAULT_ORG_ID
    });
    server.create('user');
    server.create('user', {
      role: 'manager'
    });
    server.create('user', {
      role: 'admin'
    });
    server.create('user', {
      isPartner: false
    });
    server.create('user', {
      isPartner: false,
      role: 'manager'
    });
    server.create('user', {
      isPartner: false,
      role: 'admin'
    });
    server.create('user', {
      email: 'matthias.ebner@additive.eu',
      role: 'admin'
    });
    _options.loadOrganization && (0, _organization.professional)(server);
    _options.loadUser && (0, _user.partnerMember)(server);
  };
  _exports.default = _default;
});