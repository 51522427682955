define("additive-mi/helpers/html-safe", ["exports", "@ember/component/helper", "@ember/string"], function (_exports, _helper, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _helper.helper)(function htmlSafe(params) {
    return (0, _string.htmlSafe)(params);
  });
});