define("additive-mi/controllers/instance/settings/index", ["exports", "additive-mi/config/environment", "@ember/controller", "ember-changeset", "@ember/object", "@ember/service", "ember-concurrency", "@glimmer/tracking", "additive-mi/utils/facebook", "ember-changeset-validations", "lodash.clonedeep", "lodash.merge", "additive-mi/validations/settings"], function (_exports, _environment, _controller, _emberChangeset, _object, _service, _emberConcurrency, _tracking, _facebook, _emberChangesetValidations, _lodash, _lodash2, _settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29, _descriptor30, _descriptor31, _descriptor32, _descriptor33, _descriptor34, _descriptor35, _descriptor36, _descriptor37, _descriptor38, _descriptor39, _descriptor40, _descriptor41, _descriptor42, _descriptor43, _descriptor44, _descriptor45, _descriptor46;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const {
    assign
  } = Object;
  let SettingsIndexController = _exports.default = (_dec = (0, _emberConcurrency.task)(function* () {
    try {
      const [response] = yield (0, _emberConcurrency.all)([this.authenticatedFetch.fetch(this.apiUrl), (0, _emberConcurrency.timeout)(250)]);
      if (!response?.ok) {
        throw new Error(`[SETTINGS] ${response}`);
      } else {
        this.handleApiResponse(yield response.json());
      }
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }), _dec2 = (0, _emberConcurrency.task)(function* (resource) {
    try {
      const body = (0, _lodash2.default)({}, resource);
      const [response] = yield (0, _emberConcurrency.all)([this.authenticatedFetch.fetch(this.apiUrl, {
        headers: {
          'Accept-Language': this.currentLanguage || 'de'
        },
        method: 'PUT',
        body: JSON.stringify(body)
      }, null, [422]), (0, _emberConcurrency.timeout)(250)]);
      if (!response?.ok) throw response;
      this.handleApiResponse(yield response.json());
      this.uiToast.showToast({
        title: this.intl.t('toast.success'),
        type: 'success'
      });
    } catch (e) {
      if (e?.status === 422) {
        const error = yield e.json();
        this.uiDialog.showError(null, error?.message);
        return this.loadSettings.perform();
      }
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }), _dec3 = (0, _emberConcurrency.task)(function* (changeset) {
    try {
      let newResource = (0, _lodash2.default)({}, this.resource, {
        excludedIpAddresses: changeset.get('excludedIpAddresses'),
        ignoredTrackingHosts: changeset.get('ignoredTrackingHosts'),
        sessionMergingDomains: changeset.get('sessionMergingDomains'),
        includeHosts: changeset.get('includeHosts').map(host => host.value),
        calculateConversionValuesAsRevenue: changeset.get('calculateConversionValuesAsRevenue'),
        useEmpiricalDataToCalculateRevenue: changeset.get('useEmpiricalDataToCalculateRevenue'),
        averageReservationRevenue: changeset.get('averageReservationRevenue') ? Number(changeset.get('averageReservationRevenue')) : null,
        conversionRateRequestsToReservations: typeof changeset.get('conversionRateRequestsToReservations') === 'string' && changeset.get('conversionRateRequestsToReservations') ? changeset.get('conversionRateRequestsToReservations')?.split(':')?.map(Number) : []
      });
      yield this.updateSettings.perform(newResource);
      /* Save changeset to reset dirty flag, otherwise it will trigger discard changes dialog */
      yield changeset.save();
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }), _dec4 = (0, _emberConcurrency.task)(function* (selected) {
    const value = {
      label: selected.label,
      value: selected.value
    };
    this.changeset.set(`facebookAds.businessManager.value`, value);
    // to properly apply deep object changes
    this.changeset.execute();
    const {
      userToken
    } = this.changeset.get('facebookOAuth');

    // Fetch ad-accounts and facebook pages
    const [adAccountsResponse, ownedPagesResponse, clientPagesResponse] = yield Promise.all([(0, _facebook.fbApiRequest)(`${selected.value}/owned_ad_accounts?fields=name`, userToken), (0, _facebook.fbApiRequest)(`${selected.value}/owned_pages?fields=name,access_token`, userToken), (0, _facebook.fbApiRequest)(`${selected.value}/client_pages?fields=name,access_token`, userToken)]);
    this._adAccountOptions = this.mapFacebookResponse(adAccountsResponse);
    const pagesArray = [...ownedPagesResponse, ...clientPagesResponse];
    const facebookPageOptions = pagesArray.map(item => ({
      value: item.id,
      label: item.name,
      accessToken: item.access_token
    }));
    this._facebookPageOptions = facebookPageOptions;
  }), _dec5 = (0, _emberConcurrency.task)(function* (name) {
    try {
      let resource = (0, _lodash.default)(this._resource);

      /* Toggle value */
      const nameParts = name.split('.');
      let value = this._resource;
      nameParts.forEach(part => {
        value = value?.[part];
      });
      const [last, ...paths] = nameParts.reverse();
      let nestedObject = paths.reduce((acc, el) => ({
        [el]: acc
      }), {
        [last]: !value
      });
      resource = (0, _lodash2.default)(resource, nestedObject);

      /* create new changeset to not modifing resource during update */
      const changeset = new _emberChangeset.default((0, _lodash2.default)({}, resource));
      yield this.updateSettingsWithChangeset.perform(changeset, true);
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }), _dec6 = (0, _emberConcurrency.task)(function* (property, value) {
    this.changeset.set(property, value);
    this.changeset.execute();
    yield this.updateSettingsWithChangeset.perform(this.changeset);
  }), _dec7 = (0, _emberConcurrency.task)(function* (property, value) {
    yield this.toggleChangesetProperty.perform(property, value);
  }), _dec8 = (0, _emberConcurrency.task)(function* (property, value) {
    yield this.toggleChangesetProperty.perform(property, value);
  }), _dec9 = (0, _emberConcurrency.task)(function* (property, value) {
    yield this.toggleChangesetProperty.perform(property, value);
  }), _dec10 = (0, _emberConcurrency.task)(function* (property, value) {
    yield this.toggleChangesetProperty.perform(property, value);
  }), _dec11 = (0, _emberConcurrency.task)(function* (payload) {
    // Save facebook access-token in changeset
    const facebookData = this.changeset.get('facebookOAuth');
    this.changeset.set('facebookOAuth', Object.assign({}, facebookData, payload));
    try {
      if (payload?.useSystemUser) {
        this._useSystemUserFlow = true;
        const pagesResponse = yield this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/${this.organizationSlug}/connections/facebook/pages`);
        if (!pagesResponse?.ok) throw pagesResponse;
        this._facebookPageOptions = yield pagesResponse.json();
        return;
      }
      // fetch available business managers
      const businessManagers = yield (0, _facebook.fbApiRequest)(`/me/businesses`, payload.userToken);
      this._businessManagerOptions = this.mapFacebookResponse(businessManagers);
    } catch (e) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }), _dec12 = (0, _emberConcurrency.task)(function* (payload) {
    // Set google data (userToken, userName, userId) to null
    const googleData = this.changeset.get('googleOAuth');

    // Reset selected social networks
    this.changeset.set('googleOAuth', Object.assign({}, googleData, payload, {
      hasUserToken: false,
      clientId: null,
      scope: null,
      authCode: null
    }));
    this.resetGoogleChangesetProperties();
    yield this.updateSettingsWithChangeset.perform(this.changeset);
  }), _dec13 = (0, _emberConcurrency.task)(function* (payload) {
    // Set facebook data (userToken, userName, userId) to null
    const facebookData = this.changeset.get('facebookOAuth');

    // Reset selected social networks
    this.changeset.set('facebookOAuth', Object.assign({}, facebookData, payload, {
      hasUserToken: false
    }));
    this.resetFacebookChangesetProperties();
    yield this.updateSettingsWithChangeset.perform(this.changeset);
  }), _dec14 = (0, _emberConcurrency.task)(function* (payload) {
    try {
      this._isGoogleModalReadOnly = false;
      const {
        googleOAuth
      } = this.resource;
      this.changeset.set('googleOAuth', (0, _lodash2.default)({}, googleOAuth, payload));
      this.changeset.execute();
      this._authCode = this.changeset.get('googleOAuth.authCode');
      const authCode = encodeURIComponent(this._authCode);
      yield this.updateSettingsWithChangeset.perform(this.changeset);
      this._isGoogleModalOpen = true;
      this.resetGoogleChangesetProperties();
      const managerAccountResponse = yield this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/${this.organizationSlug}/connections/google/ad-manager-accounts?auth_code=${authCode}`);
      if (!managerAccountResponse?.ok) throw managerAccountResponse;
      const managerAccounts = yield managerAccountResponse.json();
      this._googleManagerAccountOptions = managerAccounts;
      const analyticsAccountsResponse = yield this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/${this.organizationSlug}/connections/google/analytics-accounts?auth_code=${authCode}`);
      if (!analyticsAccountsResponse?.ok) throw analyticsAccountsResponse;
      const analyticsAccounts = yield analyticsAccountsResponse.json();
      this._googleAnalyticsAccountOptions = analyticsAccounts;
      this.uiToast.showToast({
        title: this.intl.t('toast.success'),
        type: 'success'
      });
    } catch (e) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }), _class = class SettingsIndexController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor4, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor5, this);
      _initializerDefineProperty(this, "uiToast", _descriptor6, this);
      _initializerDefineProperty(this, "uiPaths", _descriptor7, this);
      /**
       * Changeset of the settings resource
       *
       * @property changeset
       * @type {Changeset}
       * @default null
       */
      _initializerDefineProperty(this, "changeset", _descriptor8, this);
      /**
       * Settings resource
       *
       * @property resource
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "resource", _descriptor9, this);
      /**
       * whether the facebook account settings modal is open
       *
       * @property _isFBModalOpen
       * @type {Boolean}
       * @private
       */
      _initializerDefineProperty(this, "_isFBModalOpen", _descriptor10, this);
      /**
       * whether the facebook account settings modal is read-only
       *
       * @property _isFBModalReadOnly
       * @type {Boolean}
       * @private
       */
      _initializerDefineProperty(this, "_isFBModalReadOnly", _descriptor11, this);
      /**
       * whether the google account settings modal is open
       *
       * @property _isGoogleModalOpen
       * @type {Boolean}
       * @private
       */
      _initializerDefineProperty(this, "_isGoogleModalOpen", _descriptor12, this);
      /**
       * whether the google account settings modal is read-only
       *
       * @property _isGoogleModalReadOnly
       * @type {Boolean}
       * @private
       */
      _initializerDefineProperty(this, "_isGoogleModalReadOnly", _descriptor13, this);
      _initializerDefineProperty(this, "_googleManagerAccountOptions", _descriptor14, this);
      _initializerDefineProperty(this, "_googleAdAccountOptions", _descriptor15, this);
      _initializerDefineProperty(this, "_googleAnalyticsAccountOptions", _descriptor16, this);
      _initializerDefineProperty(this, "_googleAnalyticsPropertyOptions", _descriptor17, this);
      _initializerDefineProperty(this, "_googleWebStreamOptions", _descriptor18, this);
      _initializerDefineProperty(this, "_googleMeasurementProtocolOptions", _descriptor19, this);
      _initializerDefineProperty(this, "_authCode", _descriptor20, this);
      _initializerDefineProperty(this, "_useSystemUserFlow", _descriptor21, this);
      /**
       * available facebook pages
       *
       * @property _facebookPageOptions
       * @type {Array}
       * @private
       */
      _initializerDefineProperty(this, "_facebookPageOptions", _descriptor22, this);
      /**
       * available ad accounts
       *
       * @property _adAccountOptions
       * @type {Array}
       * @private
       */
      _initializerDefineProperty(this, "_adAccountOptions", _descriptor23, this);
      /**
       * selected ad accounts in the facebook modal
       *
       * @property _adAccountsOptions
       * @type {Array}
       * @private
       */
      _initializerDefineProperty(this, "_adAccountsOptions", _descriptor24, this);
      /**
       * available business managers
       *
       * @property _businessManagerOptions
       * @type {Array}
       * @private
       */
      _initializerDefineProperty(this, "_businessManagerOptions", _descriptor25, this);
      /**
       * available facebook pixel accounts
       *
       * @property _facebookPixelOptions
       * @type {Array}
       * @private
       */
      _initializerDefineProperty(this, "_facebookPixelOptions", _descriptor26, this);
      /**
       * whether the facebook pixel options should be shown
       * @property _showFacebookPixelOptions
       * @type {Boolean}
       * @private
       * @default false
       */
      _initializerDefineProperty(this, "_showFacebookPixelOptions", _descriptor27, this);
      /**
       * available instagram accounts
       *
       * @property _instagramOptions
       * @type {Array}
       * @private
       */
      _initializerDefineProperty(this, "_instagramOptions", _descriptor28, this);
      /**
       * whether the discard changes dialog for the name is open
       *
       * @property _isNameDiscardChangesDialog
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isNameDiscardChangesDialog", _descriptor29, this);
      /**
       * whether the name dialog is open
       *
       * @property _isNameDialog
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isNameDialog", _descriptor30, this);
      /**
       * the allowed include hosts
       *
       * @property _allowedIncludeHosts
       * @type {Array}
       * @private
       * @default []
       */
      _initializerDefineProperty(this, "_allowedIncludeHosts", _descriptor31, this);
      /**
       * copy of resource
       *
       * @property _resource
       * @type {Object}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "_resource", _descriptor32, this);
      /**
       * Fetch settings
       *
       * @function loadSettings
       * @type {Task}
       */
      _initializerDefineProperty(this, "loadSettings", _descriptor33, this);
      /**
       * Updates settings with data of given resource
       *
       * @type {Task}
       * @function updateSettings
       * @param {Object} resource The resource which should be saved
       */
      _initializerDefineProperty(this, "updateSettings", _descriptor34, this);
      /**
       * Updates settings with data of given changeset
       *
       * @param {Object} changeset the changeset
       * @type {Task}
       * @function updateSettingsWithChangeset
       */
      _initializerDefineProperty(this, "updateSettingsWithChangeset", _descriptor35, this);
      _initializerDefineProperty(this, "onBusinessManagerSelectChange", _descriptor36, this);
      /**
       * Toggles a value and update
       *
       * @param {String} name the property name
       * @type {Task}
       * @function _toggleValue
       * @private
       */
      _initializerDefineProperty(this, "_toggleValue", _descriptor37, this);
      /**
       * toggle the given property
       *
       * @function toggleProperty
       * @param {Boolean} value
       */
      _initializerDefineProperty(this, "toggleChangesetProperty", _descriptor38, this);
      _initializerDefineProperty(this, "toggleImportFacebookAdSpends", _descriptor39, this);
      _initializerDefineProperty(this, "toggleEnableFacebookServerSideTracking", _descriptor40, this);
      _initializerDefineProperty(this, "toggleEnableGoogleServerSideTracking", _descriptor41, this);
      _initializerDefineProperty(this, "toggleImportGoogleAdspends", _descriptor42, this);
      _initializerDefineProperty(this, "onFacebookLogin", _descriptor43, this);
      _initializerDefineProperty(this, "onGoogleDisconnect", _descriptor44, this);
      _initializerDefineProperty(this, "onFacebookDisconnect", _descriptor45, this);
      /**
       * updates the changeset with the google access token
       *
       * @function onGoogleLogin
       * @param {Object} payload
       */
      _initializerDefineProperty(this, "onGoogleLogin", _descriptor46, this);
    }
    get organizationSlug() {
      return this.currentUser.currentOrganization.id;
    }
    get isLoading() {
      return this.loadSettings.isRunning;
    }
    get _isUpdateChangesetRunning() {
      return this.updateSettingsWithChangeset.isRunning;
    }
    get _readOnly() {
      // if not managed, only managers and admins can edit
      if (!this.uiAppSettings?.managed) {
        return !this.currentUser.isManager;
      }

      // if managed by partner, only additive and partner users can edit
      if (this.uiAppSettings?.managedBy === 'parnter') {
        return !this.currentUser.isAdditiveUser && !this.currentUser.isPartnerUser;
      }

      // if managed by additive, only additive users can edit
      return !this.currentUser.isAdditiveUser;
    }
    get _isFBSavingDisabled() {
      return !this.changeset.get('facebookAds.facebookPage.value.value') || !this.changeset.get('facebookAds.adAccounts')?.length && !this.changeset.get('facebookAds.facebookPixel.value.value');
    }
    get _isGoogleSavingDisabled() {
      return (!this.changeset.get('googleAds.managerAccount.value.value') || !this.changeset.get('googleAds.adAccounts')?.length) && !this.changeset.get('googleAnalytics.measurementProtocol.value.value');
    }
    get apiUrl() {
      return this.uiPaths.pathsByRouteName('instance.settings', this.organizationSlug).api().url;
    }
    get googleAnalyticsDescription() {
      const {
        googleAnalyticsAccountId,
        googleAnalyticsPropertyId,
        googleAnalyticsDatasetId
      } = this._resource?.googleAnalytics;
      return [googleAnalyticsAccountId, googleAnalyticsPropertyId, googleAnalyticsDatasetId].filter(id => id).join(' - ');
    }
    get includeHostsStringified() {
      return this._resource?.includeHosts?.map(host => host.label).join(', ');
    }

    /**
     * Manage apis response and set internal values
     *
     * @param {Object} response Apis response
     * @function handleApiResponse
     */
    handleApiResponse(response) {
      const {
        settings
      } = response;
      const resource = {
        calculateConversionValuesAsRevenue: settings.calculateConversionValuesAsRevenue,
        useEmpiricalDataToCalculateRevenue: settings.useEmpiricalDataToCalculateRevenue,
        conversionRateRequestsToReservations: Array.isArray(settings.conversionRateRequestsToReservations) ? settings.conversionRateRequestsToReservations?.join(':') : '',
        averageReservationRevenue: settings.averageReservationRevenue,
        includeHosts: settings.includeHosts.allowedValues.filter(allowedValue => (settings.includeHosts.value || []).includes(allowedValue.value)),
        excludedIpAddresses: settings.excludedIpAddresses,
        ignoredTrackingHosts: settings.ignoredTrackingHosts,
        sessionMergingDomains: settings.sessionMergingDomains,
        facebookOAuth: settings.facebookOAuth,
        googleAnalytics: settings.googleAnalytics,
        facebookAds: settings.facebookAds,
        googleOAuth: settings.googleOAuth,
        googleAds: settings.googleAds
      };
      this._allowedIncludeHosts = settings.includeHosts.allowedValues;
      const validation = (0, _settings.settingsValidation)(this.intl, !settings.useEmpiricalDataToCalculateRevenue);
      const changeset = new _emberChangeset.default(resource, (0, _emberChangesetValidations.default)(validation), validation);
      assign(this, {
        // keep an untouched copy of the resource object to undo deep changes
        _resource: (0, _lodash2.default)({}, resource),
        resource,
        changeset
      });
    }
    async onSocialSelectChange(socialKey, selected, checked) {
      const value = {
        label: selected.label,
        value: selected.value
      };
      if (socialKey === 'facebookPage') {
        // Fetch instagram accounts (using facebook page accessToken)
        this.fetchInstagramOptions(selected);
      } else if (socialKey === 'systemUserPage') {
        this.changeset.set('facebookAds.facebookPage.value', value);
        this.changeset.execute();
        const instagramResponse = await this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/${this.organizationSlug}/connections/facebook/instagram-accounts?page_id=${selected.value}`);
        if (!instagramResponse?.ok) throw instagramResponse;
        this._instagramOptions = await instagramResponse.json();
        const adAccountsResponse = await this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/${this.organizationSlug}/connections/facebook/ad-accounts`);
        if (!adAccountsResponse?.ok) throw adAccountsResponse;
        this._adAccountOptions = await adAccountsResponse.json();
        return;
      } else if (socialKey === 'systemUserAdAccount') {
        this.changeset.set('facebookAds.adAccount.value', value);
        this.changeset.execute();
        const pixelsResponse = await this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/${this.organizationSlug}/connections/facebook/adspixels?ad_account_id=${selected.value}`);
        if (!pixelsResponse?.ok) throw pixelsResponse;
        this._facebookPixelOptions = await pixelsResponse.json();
        this._showFacebookPixelOptions = true;
        return;
      } else if (socialKey === 'adAccount') {
        // Fetch facebook pixel options (using adAccount accessToken)
        this.fetchFacebookPixelOptions(selected.value);
      }
      if (socialKey === 'adAccounts') {
        const adAccounts = this.changeset.get('facebookAds.adAccounts') || [];
        if (checked) {
          adAccounts.push(value);
        } else if (!checked) {
          const index = adAccounts.findIndex(item => item.value === selected.value);
          adAccounts.splice(index, 1);
        }
        this.changeset.set('facebookAds.adAccounts', adAccounts);
      } else {
        this.changeset.set(`facebookAds.${socialKey}.value`, value);
        this.changeset.execute();
      }
    }
    async onGoogleManagerAccountSelectChange(selected) {
      try {
        const value = {
          label: selected.label,
          value: selected.value
        };
        this.changeset.set('googleAds.managerAccount.value', value);
        this.changeset.set('googleAds.adAccounts', []);
        this.changeset.set('googleAnalytics.account.value', {
          label: null,
          value: null
        });
        this.changeset.set('googleAnalytics.property.value', {
          label: null,
          value: null
        });
        this.changeset.set('googleAnalytics.webStream.value', {
          label: null,
          value: null
        });
        this.changeset.set('googleAnalytics.measurementProtocol.value', {
          label: null,
          value: null
        });
        this.changeset.execute();
        this._googleAnalyticsPropertyOptions = null;
        this._googleWebStreamOptions = null;
        this._googleMeasurementProtocolOptions = null;
        const authCode = encodeURIComponent(this._authCode);
        let queryParams = '?auth_code=' + authCode;
        const adAccountsResponse = await this.authenticatedFetch.fetch(`${_environment.default.APP.apiBaseHost}/${this.organizationSlug}/connections/google/ad-accounts${queryParams}&manager_account=${selected.value}`);
        if (!adAccountsResponse?.ok) throw adAccountsResponse;
        this._googleAdAccountOptions = await adAccountsResponse.json();
      } catch (e) {
        this.uiToast.showToast({
          type: 'error',
          title: this.intl.t('toast.unexpectedError')
        });
      }
    }
    async onGooglePropertySelectChange(key, selected, checked) {
      try {
        const value = {
          label: selected.label,
          value: selected.value
        };
        if (key === 'adAccounts') {
          const adAccounts = this.changeset.get('googleAds.adAccounts') || [];
          if (checked) {
            adAccounts.push(value);
          } else if (!checked) {
            const index = adAccounts.findIndex(item => item.value === selected.value);
            adAccounts.splice(index, 1);
          }
          this.changeset.set('googleAds.adAccounts', adAccounts);
          this.changeset.execute();
          return;
        }
        if (key === 'measurement-protocols') {
          this.changeset.set('googleAnalytics.measurementProtocol.value', value);
          this.changeset.execute();
          return;
        }
        const authCode = encodeURIComponent(this._authCode);
        let queryParams = '?auth_code=' + authCode;
        let url = `${_environment.default.APP.apiBaseHost}/${this.organizationSlug}/connections/google/`;
        if (key === 'account') {
          url += 'properties';
          queryParams += `&account=${selected.value}`;
        }
        if (key === 'properties') {
          url += 'web-streams';
          queryParams += `&property=${selected.value}`;
        }
        if (key === 'web-streams') {
          url += 'measurement-protocols';
          queryParams += `&web_stream=${selected.value}`;
        }
        const [response] = await (0, _emberConcurrency.all)([this.authenticatedFetch.fetch(url + queryParams), (0, _emberConcurrency.timeout)(250)]);
        if (!response?.ok) throw response;
        const json = await response.json();
        if (key === 'account') {
          this.changeset.set('googleAnalytics.account.value', value);
          this.changeset.set('googleAnalytics.property.value', {
            label: null,
            value: null
          });
          this.changeset.set('googleAnalytics.webStream.value', {
            label: null,
            value: null
          });
          this.changeset.set('googleAnalytics.measurementProtocol.value', {
            label: null,
            value: null
          });
          this._googleAnalyticsPropertyOptions = json;
          this._googleWebStreamOptions = null;
          this._googleMeasurementProtocolOptions = null;
        }
        if (key === 'properties') {
          this.changeset.set('googleAnalytics.property.value', value);
          this.changeset.set('googleAnalytics.webStream.value', {
            label: null,
            value: null
          });
          this.changeset.set('googleAnalytics.measurementProtocol.value', {
            label: null,
            value: null
          });
          this._googleWebStreamOptions = json;
          this._googleMeasurementProtocolOptions = null;
        }
        if (key === 'web-streams') {
          this.changeset.set('googleAnalytics.webStream.value', value);
          this.changeset.set('googleAnalytics.measurementProtocol.value', {
            label: null,
            value: null
          });
          this._googleMeasurementProtocolOptions = json;
        }
        this.changeset.execute();
      } catch (e) {
        this.uiToast.showToast({
          type: 'error',
          title: this.intl.t('toast.unexpectedError')
        });
      }
    }
    /**
     * resets the social networks properties
     *
     * @function resetSocialNetworks
     */
    resetSocialNetworks() {
      this.changeset.set('facebookAds', (0, _lodash2.default)({}, this._resource.facebookAds));
      this.changeset.execute();
      this._isFBModalOpen = false;
      this._isFBModalReadOnly = false;
    }

    /**
     * resets the adwords properties
     *
     * @function resetGoogleAds
     */
    resetGoogleAds() {
      this.changeset.set('googleAds', (0, _lodash2.default)({}, this._resource.googleAds));
      this.changeset.execute();
    }

    /**
     * resets the analytics properties
     *
     * @function resetGoogleAnalytics
     */
    resetGoogleAnalytics() {
      this.changeset.set('googleAnalytics', (0, _lodash2.default)({}, this._resource.googleAnalytics));
      this.changeset.execute();
    }

    /**
     * handle changes of Google Analytics account properties
     *
     * @function onGoogleAnalyticsInputChange
     * @param {String} property
     * @param {String} value
     */
    onGoogleAnalyticsInputChange(property, value) {
      this.changeset.set(`googleAnalytics.${property}`, value);
      this.changeset.execute();
    }
    async onGoogleLoginSubmit(type) {
      if (type === 'save') {
        this.changeset.set('googleOAuth.hasUserToken', true);
        this.changeset.set('googleOAuth.authCode', this._authCode);
        this.changeset.execute();
        await this.updateSettingsWithChangeset.perform(this.changeset);
        this._isGoogleModalOpen = false;
        this._authCode = null;
      } else {
        this.resetGoogleChangesetProperties();
        this.resetGoogleOptionsProperties();
      }
    }
    async onFacebookLoginSubmit(type) {
      if (type === 'save') {
        await this.updateSettingsWithChangeset.perform(this.changeset);
        this._isFBModalOpen = false;
      } else {
        this.resetFacebookChangesetProperties();
        this._adAccountOptions = null;
        this._facebookPageOptions = null;
        this._instagramOptions = null;
        this._facebookPixelOptions = null;
        this._showFacebookPixelOptions = false;
        this._isFBModalReadOnly = false;
        this._isFBModalOpen = true;
      }
      this._useSystemUserFlow = false;
    }
    abortFBLogin() {
      if (this._isFBModalReadOnly) {
        this._isFBModalOpen = false;
        return;
      }
      this.uiDialog.showDiscardChangesConfirm(async () => {
        if (this._useSystemUserFlow) {
          this.resetFacebookChangesetProperties();
          this.changeset.set('facebookOAuth.userToken', null);
          await this.loadSettings.perform();
          this._isFBModalOpen = false;
          this._useSystemUserFlow = false;
        } else if (this._resource?.facebookAds?.businessManager?.value?.value) {
          this.resetSocialNetworks();
        } else {
          this.resetSocialNetworks();
          this.onFacebookLogin.perform({
            userToken: null,
            userId: null,
            userName: null
          });
        }
      }, this.intl.t('settings.facebookAds.facebookOAuth.loginAbortDialog.title'), this.intl.t('settings.facebookAds.facebookOAuth.loginAbortDialog.description'));
    }
    abortGoogleLogin() {
      if (this._isGoogleModalReadOnly) {
        this._isGoogleModalOpen = false;
        return;
      }
      this.uiDialog.showDiscardChangesConfirm(async () => {
        this.resetGoogleChangesetProperties();
        this.resetGoogleOptionsProperties();
        await this.loadSettings.perform();
        this._isGoogleModalOpen = false;
      }, this.intl.t('settings.googleAds.loginAbortDialog.title'), this.intl.t('settings.googleAds.loginAbortDialog.description'));
    }
    openGoogleDialog() {
      assign(this, {
        _googleManagerAccountOptions: [this.changeset.get('googleAds.managerAccount')],
        _googleAdAccountOptions: this.changeset.get('googleAds.adAccounts'),
        _googleAnalyticsAccountOptions: [this.changeset.get('googleAnalytics.account')],
        _googleAnalyticsPropertyOptions: [this.changeset.get('googleAnalytics.property')],
        _googleWebStreamOptions: [this.changeset.get('googleAnalytics.webStream')],
        _googleMeasurementProtocolOptions: [this.changeset.get('googleAnalytics.measurementProtocol')],
        _isGoogleModalReadOnly: true,
        _isGoogleModalOpen: true
      });
    }
    openFacebookDialog() {
      assign(this, {
        _businessManagerOptions: [this.changeset.get('facebookAds.businessManager.value')],
        _facebookPageOptions: [this.changeset.get('facebookAds.facebookPage.value')],
        _instagramOptions: [this.changeset.get('facebookAds.instagram.value')],
        _adAccountsOptions: this.changeset.get('facebookAds.adAccounts'),
        _adAccountOptions: [this.changeset.get('facebookAds.adAccount.value')],
        _facebookPixelOptions: [this.changeset.get('facebookAds.facebookPixel.value')],
        _showFacebookPixelOptions: true,
        _isFBModalReadOnly: true,
        _isFBModalOpen: true
      });
    }
    onIncludeHostsChange(selected, checked) {
      const includeHosts = this.changeset.get('includeHosts') || [];
      if (checked && !includeHosts.map(host => host.value).includes(selected.value)) {
        includeHosts.push(selected);
      } else if (!checked && includeHosts.map(host => host.value).includes(selected.value)) {
        const index = includeHosts.findIndex(item => item.value === selected.value);
        includeHosts.splice(index, 1);
      }
      this.changeset.set('includeHosts', [...includeHosts]);
    }
    async fetchInstagramOptions(facebookPage) {
      try {
        const instagramResponse = await (0, _facebook.fbApiRequest)(`${facebookPage.value}/instagram_accounts?fields=username`, facebookPage.accessToken);
        this._instagramOptions = this.mapFacebookResponse(instagramResponse);
      } catch (e) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }
    async fetchFacebookPixelOptions(adAccountId) {
      try {
        const {
          userToken
        } = this.changeset.get('facebookOAuth');
        const facebookPixelResponse = await (0, _facebook.fbApiRequest)(`${adAccountId}/adspixels?fields=name`, userToken);
        this._facebookPixelOptions = this.mapFacebookResponse(facebookPixelResponse);
        this._showFacebookPixelOptions = true;
      } catch (e) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }
    resetFacebookChangesetProperties() {
      this.changeset.set('facebookAds.businessManager.value', {
        label: null,
        value: null
      });
      this.changeset.set('facebookAds.adAccount.value', {
        label: null,
        value: null
      });
      this.changeset.set('facebookAds.adAccounts', []);
      this.changeset.set('facebookAds.facebookPage.value', {
        label: null,
        value: null
      });
      this.changeset.set('facebookAds.facebookPixel.value', {
        label: null,
        value: null
      });
      this.changeset.set('facebookAds.instagram.value', {
        label: null,
        value: null
      });
      this.changeset.execute();
    }
    resetGoogleChangesetProperties() {
      this.changeset.set('googleAds', null);
      this.changeset.set('googleAds.managerAccount', {
        label: null,
        value: null
      });
      this.changeset.set('googleAds.adAccounts', []);
      this.changeset.set('googleAnalytics', null);
      this.changeset.set('googleAnalytics.account', {
        label: null,
        value: null
      });
      this.changeset.set('googleAnalytics.property', {
        label: null,
        value: null
      });
      this.changeset.set('googleAnalytics.webStream', {
        label: null,
        value: null
      });
      this.changeset.set('googleAnalytics.measurementProtocol', {
        label: null,
        value: null
      });
      this.changeset.execute();
    }
    resetGoogleOptionsProperties() {
      assign(this, {
        _googleManagerAccountOptions: null,
        _googleAdAccountOptions: null,
        _googleAnalyticsAccountOptions: null,
        _googleAnalyticsPropertyOptions: null,
        _googleWebStreamOptions: null,
        _googleMeasurementProtocolOptions: null
      });
    }
    mapFacebookResponse(response) {
      return response.map(item => ({
        value: item.id,
        label: item.name || item.username
      }));
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "uiPaths", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "resource", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "_isFBModalOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "_isFBModalReadOnly", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "_isGoogleModalOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "_isGoogleModalReadOnly", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "_googleManagerAccountOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "_googleAdAccountOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "_googleAnalyticsAccountOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "_googleAnalyticsPropertyOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "_googleWebStreamOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "_googleMeasurementProtocolOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "_authCode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "_useSystemUserFlow", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "_facebookPageOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "_adAccountOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "_adAccountsOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "_businessManagerOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "_facebookPixelOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "_showFacebookPixelOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "_instagramOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "_isNameDiscardChangesDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor30 = _applyDecoratedDescriptor(_class.prototype, "_isNameDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor31 = _applyDecoratedDescriptor(_class.prototype, "_allowedIncludeHosts", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor32 = _applyDecoratedDescriptor(_class.prototype, "_resource", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor33 = _applyDecoratedDescriptor(_class.prototype, "loadSettings", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor34 = _applyDecoratedDescriptor(_class.prototype, "updateSettings", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor35 = _applyDecoratedDescriptor(_class.prototype, "updateSettingsWithChangeset", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor36 = _applyDecoratedDescriptor(_class.prototype, "onBusinessManagerSelectChange", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onSocialSelectChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSocialSelectChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onGoogleManagerAccountSelectChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onGoogleManagerAccountSelectChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onGooglePropertySelectChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onGooglePropertySelectChange"), _class.prototype), _descriptor37 = _applyDecoratedDescriptor(_class.prototype, "_toggleValue", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor38 = _applyDecoratedDescriptor(_class.prototype, "toggleChangesetProperty", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor39 = _applyDecoratedDescriptor(_class.prototype, "toggleImportFacebookAdSpends", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor40 = _applyDecoratedDescriptor(_class.prototype, "toggleEnableFacebookServerSideTracking", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor41 = _applyDecoratedDescriptor(_class.prototype, "toggleEnableGoogleServerSideTracking", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor42 = _applyDecoratedDescriptor(_class.prototype, "toggleImportGoogleAdspends", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor43 = _applyDecoratedDescriptor(_class.prototype, "onFacebookLogin", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor44 = _applyDecoratedDescriptor(_class.prototype, "onGoogleDisconnect", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor45 = _applyDecoratedDescriptor(_class.prototype, "onFacebookDisconnect", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "resetSocialNetworks", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resetSocialNetworks"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetGoogleAds", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resetGoogleAds"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetGoogleAnalytics", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "resetGoogleAnalytics"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onGoogleAnalyticsInputChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onGoogleAnalyticsInputChange"), _class.prototype), _descriptor46 = _applyDecoratedDescriptor(_class.prototype, "onGoogleLogin", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onGoogleLoginSubmit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onGoogleLoginSubmit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFacebookLoginSubmit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onFacebookLoginSubmit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "abortFBLogin", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "abortFBLogin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "abortGoogleLogin", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "abortGoogleLogin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openGoogleDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openGoogleDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openFacebookDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openFacebookDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onIncludeHostsChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onIncludeHostsChange"), _class.prototype), _class);
});