define("additive-mi/serializers/report", ["exports", "additive-mi/serializers/application", "lodash.clonedeep", "additive-mi/utils/currency-serialization"], function (_exports, _application, _lodash, _currencySerialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class ReportSerializer extends _application.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "currencyProperties", [['totalValueAdded', 'value'], ['totalValueAdded', 'customValue'], ['revenue', 'value'], ['revenue', 'customValue'], ['valueAdded', 'value'], ['valueAdded', 'customValue']]);
    }
    convertToCents(obj, prop1, prop2) {
      if (obj[prop1]?.[prop2]) {
        obj[prop1][prop2] = (0, _currencySerialization.amountToCents)(obj[prop1][prop2]);
      }
      if (obj[prop1]?.[prop2] === '') {
        obj[prop1][prop2] = null;
      }
    }
    convertToNumber(obj, prop) {
      if (obj[prop]) {
        obj[prop] = Number(obj[prop]);
      }
      if (obj[prop] === '') {
        obj[prop] = null;
      }
    }
    convertToAmount(obj, prop1, prop2) {
      if (obj[prop1]?.[prop2]) {
        obj[prop1][prop2] = (0, _currencySerialization.centsToAmount)(obj[prop1][prop2]);
      }
    }
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      // check if payload contains array or single content
      const payloadKeys = Object.keys(payload);
      const firstKey = payloadKeys[0] === 'meta' ? payloadKeys[1] : payloadKeys[0];
      const contents = Array.isArray(payload[firstKey]) ? payload[firstKey] : [payload[firstKey]];

      // transform currency properties
      contents.forEach(content => {
        // transform reportStart and reportEnd properties to integer
        if (content.reportStart && !content.reportStart.month && !content.reportStart.year) {
          const startDate = new Date(content.reportStart);
          content.reportStart = {
            year: startDate.getFullYear(),
            month: startDate.getMonth() + 1
          };
        }
        if (content.reportEnd && !content.reportEnd.month && !content.reportEnd.year) {
          const endDate = new Date(content.reportEnd);
          content.reportEnd = {
            year: endDate.getFullYear(),
            month: endDate.getMonth() + 1
          };
        }
        content.slides?.forEach(slide => {
          if (slide.totalValueAdded) {
            this.currencyProperties.forEach(([prop1, prop2]) => this.convertToAmount(slide.totalValueAdded, prop1, prop2));
          }
          if (slide.otherKpis) {
            Object.keys(slide.otherKpis)?.forEach(column => {
              slide.otherKpis[column]?.forEach(kpi => {
                if (['currency_cent'].includes(kpi.format)) {
                  kpi.value = (0, _currencySerialization.centsToAmount)(kpi.value);
                  kpi.customValue = (0, _currencySerialization.centsToAmount)(kpi.customValue);
                }
              });
            });

            // fix order of otherKpis keys
            const tmpOtherKpis = {};
            ['firstColumn', 'secondColumn', 'thirdColumn', 'forthColumn'].forEach(columnKey => {
              Object.hasOwn(slide.otherKpis, columnKey) && (tmpOtherKpis[columnKey] = slide.otherKpis[columnKey]);
            });
            slide.otherKpis = tmpOtherKpis;
          }
        });
        if (content.settings) {
          // order of settings keys
          const orderedSettingsKeys = ['useReservationData', 'showKpiInfo', 'showDescriptionSlide', 'showCharts', 'showHistoricalDataCharts', 'showNewsletterMarketingSlide', 'sessionBasis', 'cancellationsAsReservations', 'optionsAsReservations', 'calculateNewsletterOpenings', ...Object.keys(content.settings),
          // keep attribution settings at the end
          'calculateConversionValuesAsRevenue', 'useEmpiricalDataToCalculateRevenue', 'conversionRateRequestsToReservations', 'averageReservationRevenue', 'includeHosts'].reduce((acc, item) => {
            if (!acc.includes(item)) {
              acc.push(item);
            }
            return acc;
          }, []);
          const orderedSettings = {};
          orderedSettingsKeys.forEach(attributionSettingKey => {
            orderedSettings[attributionSettingKey] = content.settings?.[attributionSettingKey];
            delete content.settings?.[attributionSettingKey];
          });
          if (!orderedSettings?.includeHosts) {
            orderedSettings.includeHosts = {};
          }
          if (!orderedSettings?.includeHosts?.allowedValues?.length) {
            orderedSettings.includeHosts.value = [];
          } else if (orderedSettings?.includeHosts?.value?.length) {
            orderedSettings.includeHosts.value = orderedSettings.includeHosts.allowedValues.filter(allowedValue => orderedSettings.includeHosts.value.includes(allowedValue.value));
          }
          Object.assign(content.settings, orderedSettings);
        }

        // check if report is already in store
        const report = store.peekRecord(primaryModelClass.modelName, content.id);
        if (report) {
          // merge old content from existing report with new data from payload
          content = Object.assign({}, report, content);
        }
      });
      return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
    }
    serialize(snapshot, options) {
      let json = super.serialize(snapshot, options);
      const jsonCopy = (0, _lodash.default)(json);
      jsonCopy.slides?.forEach(slide => {
        this.currencyProperties.forEach(([prop1, prop2]) => {
          if (slide.totalValueAdded) {
            this.convertToCents(slide.totalValueAdded, prop1, prop2);
          }
        });
        if (slide.roi?.roi) {
          this.convertToNumber(slide.roi?.roi, 'customValue');
        }
        if (slide.otherKpis) {
          // transform currency properties to cents
          Object.keys(slide.otherKpis)?.forEach(column => {
            slide.otherKpis[column]?.forEach(kpi => {
              if (['currency_cent'].includes(kpi.format)) {
                kpi.value = (0, _currencySerialization.amountToCents)(kpi.value);
                kpi.customValue = (0, _currencySerialization.amountToCents)(kpi.customValue);
              }
              if (kpi.isCustom && !kpi.value) {
                kpi.value = 0;
              }
              this.convertToNumber(kpi, 'value');
              this.convertToNumber(kpi, 'customValue');
            });
          });
        }
      });

      // transform reportStart and reportEnd properties to ISO string

      if (jsonCopy.reportStart && typeof jsonCopy.reportStart !== 'string') {
        const startDate = new Date(jsonCopy.reportStart.year, jsonCopy.reportStart.month - 1, 1);
        const reportStart = startDate.toISOString();
        jsonCopy.reportStart = reportStart;
      }
      if (jsonCopy.reportEnd && typeof jsonCopy.reportEnd !== 'string') {
        const endDate = new Date(jsonCopy.reportEnd.year, jsonCopy.reportEnd.month - 1, 1);
        const reportEnd = endDate.toISOString();
        jsonCopy.reportEnd = reportEnd;
      }
      jsonCopy.settings.includeHosts = jsonCopy.settings?.includeHosts?.value?.length ? jsonCopy.settings.includeHosts.value.map(value => value.value) : [];
      return jsonCopy;
    }
  }
  _exports.default = ReportSerializer;
});