define("additive-mi/mirage/serializers/report-setting", ["exports", "additive-mi/mirage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    keyForModel() {
      return 'settings';
    }
  });
});