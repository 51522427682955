define("additive-mi/mirage/routes/settings/report-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ReportSettingRoutes = void 0;
  const ReportSettingRoutes = self => {
    self.get('/settings/reports', schema => {
      return schema.reportSettings.first();
    });
    self.put('/settings/reports', (schema, request) => {
      const attrs = JSON.parse(request.requestBody);
      const model = schema.reportSettings.first();
      return model.update(attrs);
    });
  };
  _exports.ReportSettingRoutes = ReportSettingRoutes;
});