define("additive-mi/helpers/add-one", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addOne = addOne;
  _exports.default = void 0;
  function addOne([number]) {
    return number + 1;
  }
  var _default = _exports.default = (0, _helper.helper)(addOne);
});