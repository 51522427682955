define("additive-mi/components/ami-radio-navigation", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <UiRadioButtonGroup
    @options={{@options}}
    @selected={{@selected}}
    {{on "click" this.onClick}}
    as |group option|
  >
    <group.option @class="flex-wrap mw5 mr3" @label={{option.label}} @value={{option.route}}>
      <div class="ml4 font-sm secondary">
        {{option.description}}
      </div>
    </group.option>
  </UiRadioButtonGroup>
  */
  {
    "id": "QhXrl/+1",
    "block": "[[[8,[39,0],[[4,[38,1],[\"click\",[30,0,[\"onClick\"]]],null]],[[\"@options\",\"@selected\"],[[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,3,[\"option\"]],null,[[\"@class\",\"@label\",\"@value\"],[\"flex-wrap mw5 mr3\",[30,4,[\"label\"]],[30,4,[\"route\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"ml4 font-sm secondary\"],[12],[1,\"\\n      \"],[1,[30,4,[\"description\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[3,4]]]]]],[\"@options\",\"@selected\",\"group\",\"option\"],false,[\"ui-radio-button-group\",\"on\"]]",
    "moduleName": "additive-mi/components/ami-radio-navigation.hbs",
    "isStrictMode": false
  });
  let AmiRadioNavigation = _exports.default = (_class = class AmiRadioNavigation extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
    }
    onClick() {
      const {
        selected
      } = this.args;
      this.router.transitionTo(selected);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmiRadioNavigation);
});