define("additive-mi/utils/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.onboardingData = _exports.MINIMUM_CALENDAR_DATE = _exports.LOCAL_STORAGE_FILTER_KEY = _exports.FILTER_DEFAULTS = _exports.CHART_COLORS = void 0;
  const MINIMUM_CALENDAR_DATE = _exports.MINIMUM_CALENDAR_DATE = new Date(2017, 0, 1);
  const LOCAL_STORAGE_FILTER_KEY = _exports.LOCAL_STORAGE_FILTER_KEY = 'filter-storage';
  const CHART_COLORS = _exports.CHART_COLORS = [{
    borderColor: '#4D76F1',
    backgroundColor: '#4D76F1',
    pointBorderColor: 'white'
  }, {
    borderColor: '#f3a100',
    backgroundColor: '#f3a100',
    pointBorderColor: 'white'
  }, {
    borderColor: '#8f9ea4',
    backgroundColor: '#8f9ea4',
    pointBorderColor: 'white'
  }];
  const onboardingData = intl => {
    return {
      1: [{
        title: intl.t('onboarding.1.title'),
        description: intl.t('onboarding.1.description'),
        illustration: 'marketing-insights',
        isFirstStep: true
      }, {
        title: intl.t('onboarding.2.title'),
        description: intl.t('onboarding.2.description'),
        illustration: 'step-2',
        theme: 'dark',
        isLastStep: true
      }]
    };
  };
  _exports.onboardingData = onboardingData;
  const FILTER_DEFAULTS = _exports.FILTER_DEFAULTS = {
    DEFAULT_ACTIVE_COLUMN_GROUPS: [],
    HIDEABLE_COLUMN_GROUPS_ANALYTICS: {
      conversionTypes: ['enquiries', 'reservations', 'vouchers', 'newsletter', 'other'],
      turnoverPerTypes: ['enquiryTurnover', 'reservationTurnover', 'voucherTurnover', 'ibeTurnover'],
      campaignBudgets: ['adExpenditure', 'roas', 'adRevenue'],
      conversionRates: ['sessionsVisitorRate', 'sessionsConversionRate', 'enqConversionRate'],
      actionTracking: ['actions']
    },
    HIDEABLE_COLUMN_GROUPS_CONVERSION_ATTRIBUTION: {
      all: [],
      'request,booking': ['vouchers', 'voucherTurnover'],
      request: ['reservations', 'vouchers', 'totalReservationTurnover', 'ibeTurnover', 'voucherTurnover'],
      booking: ['enquiries', 'vouchers', 'enquiryTurnover', 'voucherTurnover'],
      voucher: ['enquiries', 'reservations', 'reservationCount', 'newResCount', 'totalReservationTurnover', 'enquiryTurnover', 'reservationTurnover', 'ibeTurnover']
    },
    RESERVATION_TYPES: ['option', 'cancelled'],
    GROUPING_HIERARCHY_OPTIONS: [['source', 'medium', 'campaign'], ['medium', 'source', 'campaign']],
    DEFAULT_GROUPING_HIERARCHY: ['source', 'medium', 'campaign'],
    SESSIONBASIS_OPTIONS: ['cookieless', 'cookies']
  };
});