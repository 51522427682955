define("additive-mi/controllers/instance/analytics", ["exports", "@ember/controller", "@ember/service", "@ember/object", "additive-mi/utils/constants", "@glimmer/tracking"], function (_exports, _controller, _service, _object, _constants, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceAnalyticsController = _exports.default = (_class = class InstanceAnalyticsController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _initializerDefineProperty(this, "uiPaths", _descriptor2, this);
      _initializerDefineProperty(this, "uiState", _descriptor3, this);
      _initializerDefineProperty(this, "hiddenColumns", _descriptor4, this);
      /**
       * the formats of the analytics table
       *
       * @property tableFormats
       * @type {Object}
       * @default null
       * @private
       */
      _initializerDefineProperty(this, "tableFormats", _descriptor5, this);
      /**
       * the default hidden columns used for caching
       *
       * @property _defaultHiddenColumns
       * @type {Array}
       * @default []
       */
      _defineProperty(this, "_defaultHiddenColumns", []);
      /**
       * whether the filter navdrawer is open
       *
       * @property _isNavDrawerOpen
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isNavDrawerOpen", _descriptor6, this);
    }
    get _organizationSlug() {
      return this.currentUser.currentOrganization.id;
    }
    get _fetchUrl() {
      return this.uiPaths.pathsByRouteName('instance.analytics').api().url;
    }
    getDefaultHiddenColumns() {
      // Cache
      if (this._defaultHiddenColumns.length > 0) {
        return this._defaultHiddenColumns;
      }
      const hiddenColumns = Object.keys(_constants.FILTER_DEFAULTS.HIDEABLE_COLUMN_GROUPS_ANALYTICS).reduce((accumulator, groupKey) => {
        if (!_constants.FILTER_DEFAULTS.DEFAULT_ACTIVE_COLUMN_GROUPS.includes(groupKey)) {
          accumulator.push(..._constants.FILTER_DEFAULTS.HIDEABLE_COLUMN_GROUPS_ANALYTICS[groupKey]);
        }
        return accumulator;
      }, []);
      this._defaultHiddenColumns = hiddenColumns;
      return hiddenColumns;
    }
    openDetailDialog(rowMeta, filters) {
      if (!rowMeta || !rowMeta.id) {
        return;
      }
      this.transitionToRoute('instance.analytics.detail', rowMeta.id, {
        queryParams: {
          ...filters
        }
      });
    }
    toggleFilterDrawer() {
      this.uiState.getState('filter-navdrawer-toggle').toggle();
      this._isNavDrawerOpen = !this._isNavDrawerOpen;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiPaths", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiState", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "hiddenColumns", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "tableFormats", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_isNavDrawerOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "openDetailDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openDetailDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleFilterDrawer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleFilterDrawer"), _class.prototype), _class);
});