define("additive-mi/routes/instance/settings/snippet", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InstanceSettingsSnippetRoute extends _route.default {
    setupController(controller) {
      controller.loadSettings.perform();
    }
  }
  _exports.default = InstanceSettingsSnippetRoute;
});