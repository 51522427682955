define("additive-mi/templates/instance/stay-attribution/enquiry", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "o5C5JEHN",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"pt2 mw8 ma-auto\"],[12],[1,\"\\n    \"],[8,[39,1],[[16,\"data-test-stay-attribution-enquiry-date-filter\",true]],[[\"@hideHost\",\"@organizationSlug\",\"@onChangeFilter\"],[true,[30,0,[\"_organizationSlug\"]],[30,1,[\"update\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,2],[[16,\"data-test-stay-attribution-enquiry-table\",true]],[[\"@apiRoute\",\"@baseRoute\",\"@organizationSlug\",\"@scrollContainerSelector\",\"@filters\"],[\"enquiry-attribution/stay\",\"instance.stay-attribution.enquiry\",[30,0,[\"_organizationSlug\"]],\".ui-content\",[30,1,[\"value\"]]]],null],[1,\"\\n\\n  \"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n\"]],[1]]]]]],[\"storage\"],false,[\"ami-local-storage\",\"ami-insights/filters\",\"ami-attribution-table\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-mi/templates/instance/stay-attribution/enquiry.hbs",
    "isStrictMode": false
  });
});