define("additive-mi/components/ami-attribution-table/button", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ami-attribution-table__button pr1 pl05 shadow-1 br1 bg-white secondary flex flex-row items-center relative cpointer"
    role="button"
    data-test-ami-attribution-table--button
    {{on "click" this.click}}
  >
    {{svg-jar "arrow-chart" width=20 height=20}}
    {{#unless this.isMobile}}
      <div class="ml05">
        {{t "components.AmiAttributionTableButton.detail"}}
      </div>
    {{/unless}}
  </div>
  */
  {
    "id": "t7p4ZBRI",
    "block": "[[[11,0],[24,0,\"ami-attribution-table__button pr1 pl05 shadow-1 br1 bg-white secondary flex flex-row items-center relative cpointer\"],[24,\"role\",\"button\"],[24,\"data-test-ami-attribution-table--button\",\"\"],[4,[38,0],[\"click\",[30,0,[\"click\"]]],null],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"arrow-chart\"],[[\"width\",\"height\"],[20,20]]]],[1,\"\\n\"],[41,[51,[30,0,[\"isMobile\"]]],[[[1,\"    \"],[10,0],[14,0,\"ml05\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"components.AmiAttributionTableButton.detail\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[],false,[\"on\",\"svg-jar\",\"unless\",\"t\"]]",
    "moduleName": "additive-mi/components/ami-attribution-table/button.hbs",
    "isStrictMode": false
  });
  let AmiAttributionTableButtonComponent = _exports.default = (_class = class AmiAttributionTableButtonComponent extends _component2.default {
    get isMobile() {
      return window.innerWidth < 768;
    }
    click(e) {
      e.stopPropagation();
      this.args.onClick(...arguments);
      return false;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "click", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmiAttributionTableButtonComponent);
});