define("additive-mi/mirage/factories/setting", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    excludedIpAddresses: '8.8.8.8,8.8.4.4',
    sessionMergingDomains() {
      return ['test.com', 'simon.com'];
    },
    trackingCode() {
      return `<script>
        (function (i, s, o, g, r, a, m) {
            i['AdditiveTRObject'] = r;
            i[r] = i[r] || function () {
                (i[r].q = i[r].q || []).push(arguments)
            }, i[r].l = 1 * new Date();
            a = s.createElement(o),
                m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m)
        })(window, document, 'script', 'https://tr.additive-apps.cloud/main.js', 'ddtv');
        ddtv('init', 'xxxxx', 'xxxxxx');
        ddtv('send', 'pageview');
      </script>`;
    },
    facebookAds() {
      return {
        adAccounts: null,
        page: null,
        importFacebookAdSpends: false
      };
    },
    facebookOAuth() {
      return {
        scope: ['ads_read', 'business_management', 'catalog_management', 'ads_management', 'pages_show_list'],
        appId: '753891238595743',
        userToken: null,
        userId: null,
        userName: null
      };
    },
    googleOAuth() {
      return {
        scope: 'https://www.googleapis.com/auth/adexchange.buyer',
        clientId: '64942480172-j8fm2vavpvkmp7nqrq86rt6hvhohs50o.apps.googleusercontent.com',
        email: null,
        authorized: false,
        name: null
      };
    },
    googleAdwords() {
      const allowedValues = [{
        label: 'Ad account 1',
        value: 'option1-value'
      }, {
        label: 'Ad account 2',
        value: 'option2-value'
      }, {
        label: 'Ad account 3',
        value: 'option3-value'
      }];
      return {
        adAccounts: {
          value: null,
          allowedValues
        },
        importAdwordsAdspends: false
      };
    },
    googleAnalytics() {
      return {
        exportFacebookAdSpends: false,
        googleAnalyticsAccountId: null,
        googleAnalyticsPropertyId: null,
        googleAnalyticsDatasetId: null
      };
    },
    // attribution settings
    averageReservationRevenue() {
      return _faker.default.random.boolean() ? _faker.default.random.number(1000) : null;
    },
    conversionRateRequestsToReservations() {
      return _faker.default.random.boolean() ? [_faker.default.random.number(10), 1] : null;
    },
    calculateConversionValuesAsRevenue: () => _faker.default.random.boolean(),
    useEmpiricalDataToCalculateRevenue: () => _faker.default.random.boolean(),
    includeHosts: () => {
      const hosts = [{
        label: 'Host 1',
        value: 'host1'
      }, {
        label: 'Host 2',
        value: 'host2'
      }, {
        label: 'Host 3',
        value: 'host3'
      }];
      return {
        allowedValues: hosts,
        value: hosts.slice(0, Math.floor(Math.random() * hosts.length)).map(host => host.value)
      };
    }
  });
});