define("additive-mi/mirage/factories/enquiry-attribution-sub", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_DEPTH = 4;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    // helper to prevent infinity-loop while recursivley generation table-data
    // do not serialize this
    currentDepth: 1,
    columnCount: 1,
    meta() {
      return {
        id: `${this.currentDepth}`
      };
    },
    rowData() {
      return [`first-${this.currentDepth}`, {
        value: _faker.default.random.number(1000),
        format: 'currency_cent'
      }, {
        value: _faker.default.random.number(1000),
        format: 'currency_cent'
      }, {
        value: _faker.default.random.number(100),
        format: 'percentage'
      }, {
        value: _faker.default.random.number(1000),
        format: 'number'
      }, {
        value: _faker.default.random.number(1000),
        format: 'number'
      }, {
        value: _faker.default.random.number(1000),
        format: 'number'
      }, {
        value: '14:1',
        percentage: '7.3'
      }, {
        value: _faker.default.random.number(1000),
        format: 'number'
      }, {
        value: _faker.default.random.number(1000),
        format: 'number'
      }, {
        value: _faker.default.random.number(1000),
        format: 'currency_cent'
      }, {
        value: _faker.default.random.number(1000),
        format: 'number'
      }, {
        value: _faker.default.random.number(1000),
        format: 'number'
      }, {
        value: _faker.default.random.number(1000),
        format: 'number'
      }, {
        value: _faker.default.random.number(1000),
        format: 'currency_cent'
      }];
    },
    afterCreate(model, server) {
      const shouldDepper = _faker.default.random.boolean();
      model.update({
        meta: {
          id: model.id
        }
      });
      server.create('enquiry-attribution-detail-chart', {
        parent: model.id
      });
      server.create('enquiry-attribution-detail-table', {
        parent: model.id
      });

      /* Recursive behavior */
      if (!shouldDepper || model.currentDepth >= MAX_DEPTH) {
        model.update({
          subData: []
        });
        return;
      }
      model.update({
        subData: server.createList('enquiry-attribution-sub', _faker.default.random.number(4), {
          currentDepth: model.currentDepth + 1,
          columnCount: model.columnCount
        })
      });
    }
  });
});