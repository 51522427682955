define("additive-mi/utils/facebook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fbApiRequest = void 0;
  /**
   *  Wrapper function for calling a facebook api endpoint
   *
   * @function fbApiRequest
   * @param {String} path
   * @param {String} [method='GET']
   * @returns {Promise<Array>}
   */
  const fbApiRequest = (path, userToken, stack = [], method = 'GET') => {
    const params = {
      access_token: userToken,
      limit: 5000
    };
    return new Promise((resolve, reject) => {
      window.FB.api(path, method, params, response => {
        if (response?.error) {
          reject(response.error);
        }
        if (response?.data.length) {
          stack = stack.concat(response?.data);
        }
        return resolve(stack);
      });
    });
  };
  _exports.fbApiRequest = fbApiRequest;
});