define("additive-mi/templates/instance/analytics/detail", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Kcb4/v4E",
    "block": "[[[8,[39,0],[[16,\"data-test-analytics-detail\",true]],[[\"@apiRoute\",\"@baseRoute\",\"@detailId\",\"@organizationSlug\",\"@dateRange\",\"@groupingHierarchy\",\"@groups\",\"@reservationTypes\",\"@sessionBasis\",\"@title\"],[\"analytics\",\"instance.analytics\",[30,0,[\"detailId\"]],[30,0,[\"organizationId\"]],[28,[37,1],null,[[\"start\",\"end\"],[[30,0,[\"start\"]],[30,0,[\"end\"]]]]],[30,0,[\"groupingHierarchy\"]],[30,0,[\"groups\"]],[30,0,[\"reservationTypes\"]],[30,0,[\"sessionBasis\"]],[28,[37,2],[\"analytics.title\"],null]]],null]],[],false,[\"ami-attribution-table/detail-dialog\",\"hash\",\"t\"]]",
    "moduleName": "additive-mi/templates/instance/analytics/detail.hbs",
    "isStrictMode": false
  });
});