define("additive-mi/utils/filter-util", ["exports", "@additive-apps/ui/utils/filter-util"], function (_exports, _filterUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _filterUtil.default;
    }
  });
});