define("additive-mi/mirage/serializers/report-share-link", ["exports", "additive-mi/mirage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ReportShareLinksSerializer extends _application.default {
    keyForModel() {
      return 'share';
    }
  }
  _exports.default = ReportShareLinksSerializer;
});