define("additive-mi/templates/instance/reports/automated/detail", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "HeeOM3Dp",
    "block": "[[[41,[30,0,[\"isDetailView\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@report\",\"@onClose\"],[[30,0,[\"model\"]],[30,0,[\"closePreview\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,2],null,[[\"@report\",\"@onClose\"],[[30,0,[\"model\"]],[30,0,[\"closePreview\"]]]],null],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"ami-reports/preview\",\"ami-reports/preview-dialog\"]]",
    "moduleName": "additive-mi/templates/instance/reports/automated/detail.hbs",
    "isStrictMode": false
  });
});