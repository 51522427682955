define("additive-mi/templates/instance/settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "YMZeHkkk",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@theme\"],[[28,[37,1],[\"routes.settings.title\"],null],\"grey\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"navbar\"]],null,null,null],[1,\"\\n  \"],[8,[30,1,[\"intro\"]],null,[[\"@title\",\"@size\",\"@isMultiline\"],[[28,[37,1],[\"settings.title\"],null],\"xl\",true]],null],[1,\"\\n\\n  \"],[8,[30,1,[\"tabs\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"header\"]],null,[[\"@isUnderlined\"],[true]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,3,[\"tab\"]],null,[[\"@routePath\",\"@tabName\"],[\"instance.settings.index\",[28,[37,1],[\"routes.settings.general\"],null]]],null],[1,\"\\n      \"],[8,[30,3,[\"tab\"]],null,[[\"@routePath\",\"@tabName\"],[\"instance.settings.snippet\",[28,[37,1],[\"routes.settings.snippet\"],null]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"hasReportSettings\"]],[[[1,\"        \"],[8,[30,3,[\"tab\"]],null,[[\"@routePath\",\"@tabName\"],[\"instance.settings.reports\",[28,[37,1],[\"routes.settings.reports\"],null]]],null],[1,\"\\n        \"],[8,[30,3,[\"tab\"]],null,[[\"@routePath\",\"@tabName\"],[\"instance.settings.service-costs\",[28,[37,1],[\"routes.settings.serviceCosts\"],null]]],null],[1,\"\\n        \"],[8,[30,3,[\"tab\"]],null,[[\"@routePath\",\"@tabName\"],[\"instance.settings.budget\",[28,[37,1],[\"routes.settings.budget\"],null]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"]],[3]]]]],[1,\"\\n\\n    \"],[8,[30,2,[\"content\"]],null,[[\"@class\"],[\"flex flex-column\"]],[[\"default\"],[[[[1,\"\\n      \"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[2]]]]],[1,\"\\n\\n  \"],[8,[30,1,[\"footer\"]],null,[[\"@classNames\"],[\"flex flex-column items-end views__footer w-100\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,1,\"settings-fab-button-container\"],[14,0,\"pr1 pb1 pr2-sm pb2-sm\"],[12],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"view\",\"tabs\",\"header\"],false,[\"ui-content-views\",\"t\",\"if\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-mi/templates/instance/settings.hbs",
    "isStrictMode": false
  });
});