define("additive-mi/mirage/utils/generate-chart-data", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generateChartData = void 0;
  const generateChartData = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const numOfMonths = Math.abs((0, _dateFns.differenceInMonths)(startDate, endDate));
    const data = {
      datasets: [{
        data: [...Array(numOfMonths).keys()].map(() => Math.floor(Math.random() * 40)),
        format: 'number',
        label: 'Anfragen'
      }, {
        data: [...Array(numOfMonths).keys()].map(() => Math.floor(Math.random() * 40)),
        format: 'number',
        label: 'Reservierungen'
      }, {
        data: [...Array(numOfMonths).keys()].map(() => Math.floor(Math.random() * 40)),
        format: 'number',
        label: 'Neue Reservierer'
      }],
      labels: [...Array(numOfMonths).keys()].map(i => (0, _dateFns.format)((0, _dateFns.addMonths)(new Date(start.toString()), i), 'yyyy-MM')),
      type: 'bar'
    };
    return data;
  };
  _exports.generateChartData = generateChartData;
});