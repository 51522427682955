define("additive-mi/validators/currency", ["exports", "@additive-apps/utils/validators/currency"], function (_exports, _currency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _currency.default;
    }
  });
});