define("additive-mi/mirage/factories/image", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    width() {
      return _faker.default.random.number();
    },
    height() {
      return _faker.default.random.number();
    },
    id() {
      return _faker.default.random.uuid();
    },
    url() {
      return 'https://source.unsplash.com/random';
    },
    name() {
      return _faker.default.system.fileName();
    },
    type() {
      return 'image';
    }
  });
});