define("additive-mi/templates/reports", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "85hiu3hO",
    "block": "[[[41,[30,0,[\"model\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@html\"],[[30,0,[\"model\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,2],null,null,null],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"ami-reports/slideshow\",\"ami-reports/error-page\"]]",
    "moduleName": "additive-mi/templates/reports.hbs",
    "isStrictMode": false
  });
});