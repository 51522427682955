define("additive-mi/mirage/factories/stay-attribution-detail-chart", ["exports", "ember-cli-mirage", "additive-mi/mirage/utils/generate-chart-data", "date-fns"], function (_exports, _emberCliMirage, _generateChartData, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    type: 'compare',
    afterCreate(model) {
      const today = new Date();
      const start = new Date((0, _dateFns.getYear)(today), 0, 1);
      const end = new Date((0, _dateFns.getYear)(today) + 1, 0, 1);
      const {
        datasets,
        labels
      } = (0, _generateChartData.generateChartData)(start, end);
      model.update({
        datasets,
        labels
      });
    }
  });
});