define("additive-mi/components/ami-managed-fetch", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "ember-arg-types", "prop-types", "ember-concurrency", "@ember/service", "lodash.clonedeep"], function (_exports, _component, _templateFactory, _component2, _tracking, _emberArgTypes, _propTypes, _emberConcurrency, _service, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div {{did-update (perform this._fetchData) this.fetchUrl this.queryParams}} ...attributes>
    {{#if this._fetchData.isRunning}}
      <UiSkeleton
        @itemsPerRow={{1}}
        @items={{10}}
        @itemsSmartphone={{5}}
        @cardHeight={{52}}
        @gap={{4}}
        data-test-managed-fetch-loading-view={{true}}
      />
    {{else if this._hasFetchError}}
      <div
        class="flex flex-column items-center justify-center pa4 mv4 w-100"
        data-test-managed-fetch-error-view
      >
        <div class="mb2">{{t "components.amiManagedFetch.error"}}</div>
        <UiButton
          @label={{t "components.amiManagedFetch.retry"}}
          @onClick={{action (perform this._fetchData)}}
          data-test-managed-fetch-error-view-retry
        />
      </div>
    {{else}}
      {{yield (hash data=this._data)}}
    {{/if}}
  
  </div>
  */
  {
    "id": "vM0e2DsM",
    "block": "[[[11,0],[17,1],[4,[38,0],[[28,[37,1],[[30,0,[\"_fetchData\"]]],null],[30,0,[\"fetchUrl\"]],[30,0,[\"queryParams\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"_fetchData\",\"isRunning\"]],[[[1,\"    \"],[8,[39,3],[[16,\"data-test-managed-fetch-loading-view\",true]],[[\"@itemsPerRow\",\"@items\",\"@itemsSmartphone\",\"@cardHeight\",\"@gap\"],[1,10,5,52,4]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"_hasFetchError\"]],[[[1,\"    \"],[10,0],[14,0,\"flex flex-column items-center justify-center pa4 mv4 w-100\"],[14,\"data-test-managed-fetch-error-view\",\"\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"mb2\"],[12],[1,[28,[35,4],[\"components.amiManagedFetch.error\"],null]],[13],[1,\"\\n      \"],[8,[39,5],[[24,\"data-test-managed-fetch-error-view-retry\",\"\"]],[[\"@label\",\"@onClick\"],[[28,[37,4],[\"components.amiManagedFetch.retry\"],null],[28,[37,6],[[30,0],[28,[37,1],[[30,0,[\"_fetchData\"]]],null]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[18,2,[[28,[37,8],null,[[\"data\"],[[30,0,[\"_data\"]]]]]]],[1,\"\\n  \"]],[]]]],[]]],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"did-update\",\"perform\",\"if\",\"ui-skeleton\",\"t\",\"ui-button\",\"action\",\"yield\",\"hash\"]]",
    "moduleName": "additive-mi/components/ami-managed-fetch.hbs",
    "isStrictMode": false
  });
  /**
   * HOC which fetches data and handles errors/loading states.
   */
  let AmiManagedFetchComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _dec2 = (0, _emberArgTypes.arg)(_propTypes.string), _dec3 = (0, _emberArgTypes.arg)(_propTypes.string), _dec4 = (0, _emberConcurrency.task)(function* () {
    try {
      this._abortController?.abort();
      this._hasFetchError = false;
      let tasks = [];
      let url = this.fetchUrl;
      if (this.queryParams) {
        url += `?${this.queryParams}`;
      }
      this._abortController = new AbortController();
      const request = this.authenticatedFetch.fetch(url, {
        signal: this._abortController && this._abortController.signal
      });
      tasks.push(request);
      tasks.push((0, _emberConcurrency.timeout)(400));
      const [response] = yield (0, _emberConcurrency.all)(tasks);
      this._abortController = null;
      if (!response || !response.ok) {
        throw new Error();
      }
      const json = yield response.json();
      const serializedResponse = this.serializer(json);
      this._data = (0, _lodash.default)(serializedResponse);
    } catch (error) {
      this._hasFetchError = true;
    }
  })
  // eslint-disable-next-line prettier/prettier
  .restartable(), _dec5 = (0, _emberArgTypes.arg)(_propTypes.func), _class = class AmiManagedFetchComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "fetchUrl", _descriptor2, this);
      _initializerDefineProperty(this, "payloadRootKey", _descriptor3, this);
      _initializerDefineProperty(this, "queryParams", _descriptor4, this);
      _initializerDefineProperty(this, "_abortController", _descriptor5, this);
      _initializerDefineProperty(this, "_hasFetchError", _descriptor6, this);
      _initializerDefineProperty(this, "_data", _descriptor7, this);
      /**
       * Fetches data
       *
       * @function _fetchData
       * @type {Task}
       */
      _initializerDefineProperty(this, "_fetchData", _descriptor8, this);
      _initializerDefineProperty(this, "serializer", _descriptor9, this);
      this._fetchData.perform();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fetchUrl", [_dec, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "payloadRootKey", [_dec2, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "queryParams", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_abortController", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_hasFetchError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_data", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "_fetchData", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "serializer", [_dec5, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return json => {
        /* Default serializer assumes responses data structure matches additive-table-structure */
        return {
          columns: (this.payloadRootKey ? json[this.payloadRootKey] : json).labels,
          rows: (this.payloadRootKey ? json[this.payloadRootKey] : json).data
        };
      };
    }
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmiManagedFetchComponent);
});