define("additive-mi/mirage/serializers/enquiry-attribution-detail-table", ["exports", "additive-mi/mirage/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _application.default.extend({
    root: false,
    embed: true
  });
});