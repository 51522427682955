define("additive-mi/mirage/scenarios/app/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  /**
   * This is the default scenario for normal app-use (development, staging)
   */
  function _default(server) {
    server.create('setting');
    server.create('report-setting');
    server.create('service-cost', 'recent');
    server.create('service-cost', 'past');
    server.create('budget', 'recent');
    server.create('budget', 'past');
    server.create('enquiry-attribution');
    server.create('analytic');
    server.create('conversion-attribution');
    server.create('stay-attribution');
    server.create('report-configuration');
    for (let i = 0; i < 10; i++) {
      server.create('report', {
        type: 'automated'
      });
      server.create('report', {
        type: 'custom'
      });
    }
    server.passthrough('https://staging.account.additive-apps.tech/**');
    server.passthrough('https://api.staging.multimedia.additive-apps.tech/**');
    server.passthrough('https://graph.facebook.com/**');
    server.passthrough('https://www.facebook.com/**');
    server.passthrough('https://z-p3-graph.facebook.com/**');
    server.get(`${server.urlPrefix}/marsinator-big`, () => {
      let json = {
        organization: {
          contentLanguages: ['de', 'en', 'it'],
          defaultLanguage: 'de',
          planName: 'starter_yearly',
          currency: 'CHF'
        }
      };
      return json;
    });
    server.get(`${server.urlPrefix}/marsinator-big/me`, () => {
      let json = {
        id: 'ec156759-fcdd-41a3-9b7b-201fe6bd2e3e',
        email: 'member@additive.eu',
        fullName: 'Member',
        role: 'member',
        isPartner: true,
        isAdditive: true
      };
      return json;
    });
    server.get(`${server.urlPrefix}/testhotel-post-com`, () => {
      let json = {
        organization: {
          contentLanguages: ['de', 'en', 'it'],
          defaultLanguage: 'de',
          planName: 'starter_yearly',
          modules: ['campaign-report'],
          currency: 'EUR'
        }
      };
      return json;
    });
    server.get(`${server.urlPrefix}/testhotel-post-com/me`, () => {
      let json = {
        id: 'ec156759-fcdd-41a3-9b7b-201fe6bd2e3e',
        email: 'matthias.ebner@additive.eu',
        fullName: 'Admin',
        role: 'admin',
        isPartner: true,
        isAdditive: true
      };
      return json;
    });
    server.get(`${server.urlPrefix}/hotel-adler-it`, () => {
      let json = {
        organization: {
          contentLanguages: ['de', 'en', 'it'],
          defaultLanguage: 'de',
          planName: 'starter_yearly',
          currency: 'EUR'
        }
      };
      return json;
    });
    server.get(`${server.urlPrefix}/hotel-adler-it/me`, () => {
      let json = {
        id: 'ec156759-fcdd-41a3-9b7b-201fe6bd2e3e',
        email: 'admin@additive.eu',
        fullName: 'Admin',
        role: 'admin',
        isPartner: true,
        isAdditive: true
      };
      return json;
    });
    server.get(`${server.urlPrefix}/marsinator2-big/me`, () => {
      const json = {
        id: 'ec156759-fcdd-41a3-9b7b-201fe6bd2e3e',
        email: 'admin@additive.eu',
        fullName: 'Admin',
        role: 'admin',
        isPartner: true,
        isAdditive: true
      };
      return json;
    });
    server.get(`${server.urlPrefix}/marsinator2-big/`, () => {
      const json = {
        organization: {
          contentLanguages: ['de', 'en', 'it'],
          defaultLanguage: 'de',
          planName: 'starter_yearly',
          currency: 'CHF'
        }
      };
      return json;
    });
    server.get(`${server.urlPrefix}/profi-marsinator-big-2`, () => {
      let json = {
        organization: {
          contentLanguages: ['de', 'en', 'it'],
          defaultLanguage: 'de',
          planName: 'starter_yearly',
          currency: 'CHF'
        }
      };
      return json;
    });
    server.get(`${server.urlPrefix}/profi-marsinator-big-2/me`, () => {
      let json = {
        id: 'ec156759-fcdd-41a3-9b7b-201fe6bd2e3e',
        email: 'admin@additive.eu',
        fullName: 'non-partner-Admin',
        role: 'admin',
        isPartner: false,
        isAdditive: false
      };
      return json;
    });
    server.passthrough(`${server.urlPrefix}/${server.namespace}`);

    // match organization user and organization to avoid fetch errors
    server.get(`${server.urlPrefix}/*/me`, () => {
      const json = {
        id: 'ec156759-fcdd-41a3-9b7b-201fe6bd2e3e',
        email: 'admin@additive.eu',
        fullName: 'Admin',
        role: 'admin',
        isPartner: true,
        isAdditive: true
      };
      return json;
    });
    server.get(`${server.urlPrefix}/**/`, () => {
      const json = {
        organization: {
          contentLanguages: ['de', 'en', 'it'],
          defaultLanguage: 'de',
          planName: 'starter_yearly',
          currency: 'EUR'
        }
      };
      return json;
    });
  }
});