define("additive-mi/mirage/routes/alerts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AlertsRequest = void 0;
  const AlertsRequest = self => {
    self.get('/alerts', () => {
      return [{
        message: 'Letztes aufgezeichnetes Tracking: {{date}} um {{time}}.',
        date: '12.04.2022',
        time: '12:00',
        severity: 'error'
      }, {
        message: 'Letztes berechnete Session: {{date}} um {{time}}.',
        date: '12.04.2022',
        time: '12:00',
        severity: 'info'
      }];
    });
  };
  _exports.AlertsRequest = AlertsRequest;
});