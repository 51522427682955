define("additive-mi/components/ami-reports/settings-navdrawer/item", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flex justify-between font-sm mb2 {{if @isMultiline 'flex-column gap1' 'items-center'}}">
    <div class="flex items-center">
      <div class="mr1">
        {{@label}}
      </div>
  
      {{#if @description}}
        <UiPopover as |pop|>
          <pop.trigger>
            {{svg-jar "info" class="secondary"}}
          </pop.trigger>
          <pop.content>
            {{@description}}
          </pop.content>
        </UiPopover>
      {{/if}}
    </div>
  
    {{#if (eq @type "switch")}}
      <UiSwitch @value={{@value}} @isReadOnly={{true}} />
    {{else if (eq @type "rate")}}
      <div class="secondary">
        {{#if @value}}
          {{concat @value.firstObject ":" @value.lastObject}}
        {{else}}
          {{"-"}}
        {{/if}}
      </div>
    {{else}}
      <div class="secondary">
        {{or @value "-"}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "m03TURPC",
    "block": "[[[10,0],[15,0,[29,[\"flex justify-between font-sm mb2 \",[52,[30,1],\"flex-column gap1\",\"items-center\"]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex items-center\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"mr1\"],[12],[1,\"\\n      \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[41,[30,3],[[[1,\"      \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,4,[\"trigger\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,2],[\"info\"],[[\"class\"],[\"secondary\"]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n        \"],[8,[30,4,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n          \"],[1,[30,3]],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[4]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n\"],[41,[28,[37,3],[[30,5],\"switch\"],null],[[[1,\"    \"],[8,[39,4],null,[[\"@value\",\"@isReadOnly\"],[[30,6],true]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,3],[[30,5],\"rate\"],null],[[[1,\"    \"],[10,0],[14,0,\"secondary\"],[12],[1,\"\\n\"],[41,[30,6],[[[1,\"        \"],[1,[28,[35,5],[[30,6,[\"firstObject\"]],\":\",[30,6,[\"lastObject\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,\"-\"],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"secondary\"],[12],[1,\"\\n      \"],[1,[28,[35,6],[[30,6],\"-\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]],[]]],[13]],[\"@isMultiline\",\"@label\",\"@description\",\"pop\",\"@type\",\"@value\"],false,[\"if\",\"ui-popover\",\"svg-jar\",\"eq\",\"ui-switch\",\"concat\",\"or\"]]",
    "moduleName": "additive-mi/components/ami-reports/settings-navdrawer/item.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});