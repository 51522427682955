define("additive-mi/mirage/factories/analytic", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    labels() {
      return [{
        key: 'first',
        name: ''
      }, {
        key: 'b',
        name: 'Nutzer',
        description: 'Ich bin die B, yea!'
      }, {
        key: 'adExpenditure',
        name: 'Werbeausgaben'
      }, {
        key: 'adRevenue',
        name: 'Werbeeinnahmen'
      }, {
        key: 'sessionsVisitorRate',
        name: 'Sitzungen:Nutzer'
      }, {
        key: 'enquiries',
        name: 'Anfragen'
      }, {
        key: 'reservations',
        name: 'Buchungen'
      }, {
        key: 'vouchers',
        name: 'Gutscheinkäufe'
      }, {
        key: 'newsletter',
        name: 'NL-Anmeldungen'
      }, {
        key: 'other',
        name: 'Andere'
      }, {
        key: 'k',
        name: 'Reservierungen'
      }, {
        key: 'l',
        name: 'Neue Res.'
      }, {
        key: 'enqConversionRate',
        name: 'Anfragen:Res'
      }, {
        key: 'sessionsConversionRate',
        name: 'Sitzungen:Conv.'
      }, {
        key: 'n',
        name: 'Werbebudget'
      }, {
        key: 'roas',
        name: 'ROAS'
      }, {
        key: 'p',
        name: 'Res. Umsatz'
      }, {
        key: 'enquiryTurnover',
        name: 'Anfragenumsatz'
      }, {
        key: 'reservationTurnover',
        name: 'Buchungsumsatz'
      }, {
        key: 'voucherTurnover',
        name: 'Gutscheinumsatz'
      }, {
        key: 'ibeTurnover',
        name: 'IBE-Conv.-Ums.'
      }, {
        key: 't',
        name: 'Wertschöpfung'
      }];
    },
    data() {
      let rows = [];
      const generateRow = () => {
        return [_faker.default.lorem.word(), {
          format: 'number',
          value: _faker.default.random.number(1000)
        }, {
          format: 'number',
          value: _faker.default.random.number(1000)
        }, {
          format: 'currency_cent',
          value: _faker.default.random.number(5000) * 100
        }, {
          format: 'currency_cent',
          value: _faker.default.random.number(5000) * 100
        }, {
          format: 'number',
          value: _faker.default.random.number(666),
          percentage: _faker.default.random.number(100)
        }, {
          format: 'number',
          value: _faker.default.random.number(1000)
        }, {
          format: 'number',
          value: _faker.default.random.number(1000)
        }, {
          format: 'number',
          value: _faker.default.random.number(1000)
        }, {
          format: 'number',
          value: _faker.default.random.number(1000)
        }, {
          format: 'number',
          value: _faker.default.random.number(1000)
        }, {
          format: 'number',
          value: _faker.default.random.number(1000),
          tooltip: {
            type: 'table',
            content: {
              labels: ['Res.', 'Optionen', 'Stornierungen'],
              data: [[{
                format: 'number',
                value: _faker.default.random.number(1000)
              }, {
                format: 'number',
                value: _faker.default.random.number(1000)
              }, {
                format: 'number',
                value: _faker.default.random.number(1000)
              }]]
            }
          }
        }, {
          format: 'number',
          value: _faker.default.random.number(666),
          percentage: _faker.default.random.number(100)
        }, {
          format: 'currency_cent',
          value: _faker.default.random.number(5000) * 100
        }, {
          format: 'percentage',
          value: _faker.default.random.number(200)
        }, {
          format: 'currency_cent',
          value: _faker.default.random.number(5000) * 100
        }, {
          format: 'currency_cent',
          value: _faker.default.random.number(5000) * 100
        }, {
          format: 'currency_cent',
          value: _faker.default.random.number(5000) * 100
        }, {
          format: 'currency_cent',
          value: _faker.default.random.number(5000) * 100
        }, {
          format: 'currency_cent',
          value: _faker.default.random.number(5000) * 100
        }, {
          format: 'currency_cent',
          value: _faker.default.random.number(5000) * 100
        }, {
          format: 'currency_cent',
          value: _faker.default.random.number(5000) * 100
        }];
      };
      for (let index = 0; index < 30; index++) {
        rows.push({
          rowData: generateRow(),
          meta: {
            id: index + 1
          },
          subData: [{
            rowData: generateRow(),
            subData: []
          }]
        });
      }
      return rows;
    }
  });
});