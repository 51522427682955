define("additive-mi/utils/helpers", ["exports", "@ember/array", "date-fns", "additive-mi/utils/constants"], function (_exports, _array, _dateFns, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasChangedProperties = _exports.getDefaultOrganizationFilters = _exports.getDefaultFilters = _exports.getDefaultFilterDates = void 0;
  /**
   * returns the default date range for the filter
   *  { start: first day of last month, end: last day of last month }
   *
   * @function getDefaultFilterDates
   */
  const getDefaultFilterDates = () => {
    const today = (0, _dateFns.addMonths)(new Date(), -1);
    const start = (0, _dateFns.format)(today.setDate(1), 'yyyy-MM-dd');
    const end = (0, _dateFns.format)((0, _dateFns.lastDayOfMonth)(today), 'yyyy-MM-dd');
    return {
      start,
      end
    };
  };

  /**
   * returns the filters for the analytics and conversion attribution
   *
   * @function getDefaultFilters
   */
  _exports.getDefaultFilterDates = getDefaultFilterDates;
  const getDefaultFilters = () => {
    return {
      reservationTypes: [],
      groupingHierarchy: _constants.FILTER_DEFAULTS.DEFAULT_GROUPING_HIERARCHY,
      conversionType: '',
      attributionMode: ''
    };
  };

  /**
   * returns the organization filters for the analytics and conversion attribution
   *
   * @function getDefaultOrganizationFilters
   */
  _exports.getDefaultFilters = getDefaultFilters;
  const getDefaultOrganizationFilters = () => {
    return {
      hosts: '',
      groups: []
    };
  };

  /**
   * Check whether filterProperties is a subset of activeFilters
   * return true if filterProperties contains properties which don't exist in activeFilters or
   * if the properties differ
   *
   * @param {Object} activeFilters
   * @param {Object} filterProperties
   * @returns {Boolean}
   */
  _exports.getDefaultOrganizationFilters = getDefaultOrganizationFilters;
  const hasChangedProperties = (activeFilters, filterProperties) => {
    let changed = false;
    Object.keys(filterProperties).some(filterKey => {
      if ((0, _array.isArray)(filterProperties[filterKey])) {
        if ((0, _array.isArray)(activeFilters[filterKey]) && filterProperties[filterKey].length === activeFilters[filterKey].length || typeof activeFilters[filterKey] === 'undefined' && filterProperties[filterKey].length === 0) {
          filterProperties[filterKey].some((property, index) => {
            if (activeFilters[filterKey].indexOf(property) !== index) {
              changed = true;
              return;
            }
          });
        } else {
          changed = true;
          return;
        }
      } else if (typeof filterProperties[filterKey] === 'string' && filterProperties[filterKey] !== activeFilters[filterKey]) {
        changed = true;
        return;
      }
    });
    return changed;
  };
  _exports.hasChangedProperties = hasChangedProperties;
});