define("additive-mi/routes/instance/stay-attribution/index", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InstanceStayAttributionIndexRoute extends _route.default {
    redirect() {
      this.transitionTo('instance.stay-attribution.enquiry');
    }
  }
  _exports.default = InstanceStayAttributionIndexRoute;
});