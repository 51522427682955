define("additive-mi/helpers/cents-to-amount", ["exports", "@ember/component/helper", "additive-mi/utils/currency-serialization"], function (_exports, _helper, _currencySerialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.centsToAmount = void 0;
  const centsToAmount = params => {
    if (!params || !Array.isArray(params) || params.length === 0 || isNaN(parseFloat(params[0]))) {
      return params;
    }
    const amount = Math.floor(100 * params[0]) / 100;
    return amount && (0, _currencySerialization.centsToAmount)(amount).toFixed(2);
  };
  _exports.centsToAmount = centsToAmount;
  var _default = _exports.default = (0, _helper.helper)(centsToAmount);
});