define("additive-mi/mirage/factories/conversion-attribution", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    labels() {
      return [{
        key: 'first',
        name: ''
      }, {
        key: 'enquiries',
        name: 'Anfragen'
      }, {
        key: 'reservations',
        name: 'Buchungen'
      }, {
        key: 'vouchers',
        name: 'Gutscheinkäufe'
      }, {
        key: 'reservationCount',
        name: 'Reservierungen'
      }, {
        key: 'newResCount',
        name: 'Neue Res.'
      }, {
        key: 'totalReservationTurnover',
        name: 'Res. Umsatz'
      }, {
        key: 'enquiryTurnover',
        name: 'Anfragenumsatz'
      }, {
        key: 'reservationTurnover',
        name: 'Buchungsumsatz'
      }, {
        key: 'ibeTurnover',
        name: 'IBE-Conv.-Ums.'
      }, {
        key: 'voucherTurnover',
        name: 'Gutschein-Conv.-Ums.'
      }];
    },
    data() {
      let rows = [];
      const generateRow = depth => {
        return [depth === 0 ? 'Gesamt' : _faker.default.lorem.words(5).replaceAll(' ', ''), {
          format: 'number',
          value: _faker.default.random.number(1000)
        }, {
          format: 'number',
          value: _faker.default.random.number(1000)
        }, {
          format: 'number',
          value: _faker.default.random.number(1000)
        }, {
          format: 'number',
          value: _faker.default.random.number(1000)
        }, {
          format: 'number',
          value: _faker.default.random.number(1000)
        }, {
          format: 'currency_cent',
          value: _faker.default.random.number(5000) * 100
        }, {
          format: 'currency_cent',
          value: _faker.default.random.number(5000) * 100
        }, {
          format: 'currency_cent',
          value: _faker.default.random.number(5000) * 100
        }, {
          format: 'number',
          value: _faker.default.random.number(1000)
        }, {
          format: 'currency_cent',
          value: _faker.default.random.number(5000) * 100
        }];
      };
      const subData = [];
      for (let index = 0; index < 30; index++) {
        subData.push({
          rowData: generateRow(1),
          subData: [{
            rowData: generateRow(2),
            subData: []
          }]
        });
      }
      rows.push({
        rowData: generateRow(0),
        meta: {
          id: 1
        },
        subData
      });
      return rows;
    }
  });
});