define("additive-mi/mirage/factories/user", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* TODO: Add me to an addon */
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    email: _faker.default.internet.email(),
    givenName: () => `${_faker.default.name.firstName()} ${_faker.default.name.lastName()}`,
    isAdditive: true,
    isPartner: true,
    isSuperAdmin: false,
    isSupport: false,
    role: 'member'
  });
});