define("additive-mi/templates/instance/enquiry-attribution", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "IGbdfQFt",
    "block": "[[[8,[39,0],null,[[\"@title\",\"@isPlainView\"],[[28,[37,1],[\"routes.enquiryAttribution.title\"],null],true]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"navbar\"]],null,null,null],[1,\"\\n\\n  \"],[8,[30,1,[\"intro\"]],null,[[\"@classNames\",\"@title\",\"@description\",\"@size\",\"@isMultiline\"],[\"mw8 mh-auto\",[28,[37,1],[\"enquiryAttribution.title\"],null],[28,[37,1],[\"enquiryAttribution.description\"],null],\"xl\",true]],null],[1,\"\\n\\n  \"],[8,[30,1,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"view\"],false,[\"ui-content-views\",\"t\",\"component\",\"-outlet\"]]",
    "moduleName": "additive-mi/templates/instance/enquiry-attribution.hbs",
    "isStrictMode": false
  });
});