define("additive-mi/helpers/date-format", ["exports", "@additive-apps/utils/helpers/date-format"], function (_exports, _dateFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "dateFormat", {
    enumerable: true,
    get: function () {
      return _dateFormat.dateFormat;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dateFormat.default;
    }
  });
});