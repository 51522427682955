define("additive-mi/controllers/instance/settings/reports", ["exports", "@ember/controller", "ember-changeset", "@ember/object", "@ember/service", "ember-concurrency", "@glimmer/tracking", "ember-changeset-validations", "lodash.merge", "@additive-apps/ui/utils/dom-util", "additive-mi/validations/settings"], function (_exports, _controller, _emberChangeset, _object, _service, _emberConcurrency, _tracking, _emberChangesetValidations, _lodash, _domUtil, _settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceSettingsReportsController = _exports.default = (_dec = (0, _emberConcurrency.task)(function* () {
    try {
      const [response] = yield (0, _emberConcurrency.all)([this.authenticatedFetch.fetch(this.apiUrl), (0, _emberConcurrency.timeout)(250)]);
      if (!response || !response.ok) {
        throw new Error(`[SETTINGS] ${response}`);
      } else {
        const json = yield response.json();
        this.handleApiResponse(json);
      }
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }), _dec2 = (0, _emberConcurrency.task)(function* () {
    const changeset = this.changeset;
    try {
      if (typeof changeset.get('generationDay') === 'string') {
        changeset.set('generationDay', parseInt(changeset.get('generationDay')));
      }
      let newResource = (0, _lodash.default)({}, this.resource, {
        generationDay: changeset.get('generationDay'),
        emailReceivers: changeset.get('emailReceivers')
      });
      const body = (0, _lodash.default)({}, newResource);
      const request = yield this.authenticatedFetch.fetch(this.apiUrl, {
        headers: {
          'Accept-Language': this.currentLanguage || 'de'
        },
        method: 'PUT',
        body: JSON.stringify(body)
      });
      const [response] = yield (0, _emberConcurrency.all)([request, (0, _emberConcurrency.timeout)(250)]);
      if (!response || !response.ok) {
        throw new Error(`[SETTINGS - UPDATE] general ${response}`);
      }
      this.handleApiResponse(yield response.json());
      yield (0, _domUtil.nextTick)();
      this.uiToast.showToast({
        title: this.intl.t('toast.success'),
        type: 'success'
      });
    } catch (error) {
      this.uiToast.showToast({
        title: this.intl.t('toast.unexpectedError'),
        type: 'error'
      });
    }
  }), _class = class InstanceSettingsReportsController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor4, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor5, this);
      _initializerDefineProperty(this, "uiToast", _descriptor6, this);
      _initializerDefineProperty(this, "uiPaths", _descriptor7, this);
      /**
       * Changeset of the settings resource
       *
       * @property changeset
       * @type {Changeset}
       * @default null
       */
      _initializerDefineProperty(this, "changeset", _descriptor8, this);
      /**
       * Settings resource
       *
       * @property resource
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "resource", _descriptor9, this);
      /**
       * Internal resource
       *
       * @property _resource
       * @type {Boolean}
       * @private
       */
      _initializerDefineProperty(this, "_resource", _descriptor10, this);
      /**
       * Whether the email receivers dialog is open
       *
       * @property _isDialogOpen
       * @type {Boolean}
       * @default false
       * @private
       */
      _initializerDefineProperty(this, "_isDialogOpen", _descriptor11, this);
      /**
       * Fetch settings
       *
       * @function loadSettings
       * @type {Task}
       */
      _initializerDefineProperty(this, "loadSettings", _descriptor12, this);
      /**
       * Updates settings with data of given resource
       *
       * @type {Task}
       * @function updateSettings
       * @param {Object} resource The resource which should be saved
       */
      _initializerDefineProperty(this, "updateSettings", _descriptor13, this);
    }
    get organizationSlug() {
      return this.currentUser?.currentOrganization?.id;
    }
    get _readOnly() {
      // if not managed, only managers and admins can edit
      if (!this.uiAppSettings?.managed) {
        return !this.currentUser.isManager;
      }

      // if managed by partner, only additive and partner users can edit
      if (this.uiAppSettings?.managedBy === 'parnter') {
        return !this.currentUser.isAdditiveUser && !this.currentUser.isPartnerUser;
      }

      // if managed by additive, only additive users can edit
      return !this.currentUser.isAdditiveUser;
    }
    get _formattedEmailReceivers() {
      const emailReceivers = this.resource?.emailReceivers || [];
      return emailReceivers.join(', ');
    }

    /**
     * API url for report settings
     */
    get apiUrl() {
      const baseUrl = this.uiPaths.pathsByRouteName('instance.settings', this.organizationSlug).api().url;
      return `${baseUrl}/reports`;
    }

    /**
     * Manage apis response and set internal values
     *
     * @param {Object} response Apis response
     * @function handleApiResponse
     */
    handleApiResponse(response) {
      const {
        settings
      } = response;
      const resource = {
        generationDay: settings.generationDay,
        emailReceivers: settings.emailReceivers
      };
      const validation = (0, _settings.reportSettingsValidation)(this.intl);
      const changeset = new _emberChangeset.default(resource, (0, _emberChangesetValidations.default)(validation), validation);
      this._resource = (0, _lodash.default)({}, resource);
      this.resource = resource;
      this.changeset = changeset;
    }
    openDialog() {
      if (this._readOnly) {
        return;
      }
      this._isDialogOpen = true;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "uiPaths", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "resource", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "_resource", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "_isDialogOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "loadSettings", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "updateSettings", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "openDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openDialog"), _class.prototype), _class);
});