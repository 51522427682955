define("additive-mi/mirage/routes/performance-indicators", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PerformanceIndicatorsRequest = void 0;
  const PerformanceIndicatorsRequest = self => {
    self.get('/performance-indicators', (_, req) => {
      const {
        start,
        end
      } = req.queryParams;
      return {
        sessionsEnquiryRatio: {
          type: 'compare',
          change: 59.35,
          current: {
            value: {
              value: start ? Math.random() * 3000 : 335.68,
              percentage: 5.5
            },
            type: 'yearly-relative',
            date: end ? (0, _dateFns.getYear)(end) : 2019
          },
          compare: {
            value: 210271.76,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) - 1 : 2019
          }
        },
        enquiryBookingRatio: {
          type: 'compare',
          change: 20,
          current: {
            value: {
              value: start ? Math.floor(Math.random() * 200) : 90,
              percentage: 15
            },
            type: 'yearly-relative',
            date: end ? (0, _dateFns.getYear)(end) : 2019
          },
          compare: {
            value: 100,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) - 1 : 2019
          }
        },
        avgReservationsTurnover: {
          type: 'compare',
          change: 4.19,
          current: {
            value: start ? Math.floor(Math.random() * 2000) : 1200,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) : 2019
          },
          compare: {
            value: 1274,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) - 1 : 2019
          }
        },
        avgEnquiryValue: {
          type: 'compare',
          change: 32.63,
          current: {
            value: start ? Math.floor(Math.random() * 6000) : 37000000,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) : 2019
          },
          compare: {
            value: 6985,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) - 1 : 2019
          }
        },
        avgSessionValue: {
          type: 'compare',
          change: 32.63,
          current: {
            value: start ? Math.floor(Math.random() * 6000) : 15000000,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) : 2019
          },
          compare: {
            value: 6985,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) - 1 : 2019
          }
        }
      };
    });
  };
  _exports.PerformanceIndicatorsRequest = PerformanceIndicatorsRequest;
});