define("additive-mi/components/ami-settings/google-settings", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "ember-concurrency", "ember-arg-types", "prop-types", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _object, _service, _emberConcurrency, _emberArgTypes, _propTypes, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <this.controlPanelGroup.panel
    @label={{t "settings.googleAds.label"}}
    @value={{this.connectedText}}
    @noink={{true}}
    as |Panel|
  >
    <Panel.left>
      <UiPopover as |pop|>
        <pop.trigger>
          <UiStatus @state={{this.googleState}} />
        </pop.trigger>
        <pop.content>
          {{#if this.isConnected}}
            {{t "settings.oAuth.connectedPopover" user=this.value.name}}
          {{else}}
            {{t "settings.oAuth.disconnectedPopover"}}
          {{/if}}
        </pop.content>
      </UiPopover>
    </Panel.left>
    {{#unless this.readOnly}}
      <Panel.right>
        <div class="flex items-start gap2">
          {{#if this.isConnected}}
            <UiIconButton
              @icon="connect"
              @label={{t "settings.oAuth.reconnect"}}
              @onClick={{this.connect}}
            />
            <UiIconButton
              @label={{t "settings.oAuth.disconnect"}}
              @icon="disconnect"
              @isDanger={{true}}
              @onClick={{this.disconnect}}
            />
            <UiIconButton @icon="info" @isTertiary={{true}} @onClick={{this.onClick}} />
          {{else}}
            <UiIconButton
              @icon="connect"
              @label={{t "settings.oAuth.connect"}}
              @onClick={{this.connect}}
            />
          {{/if}}
        </div>
      </Panel.right>
    {{/unless}}
  </this.controlPanelGroup.panel>
  */
  {
    "id": "hh32AyGs",
    "block": "[[[8,[30,0,[\"controlPanelGroup\",\"panel\"]],null,[[\"@label\",\"@value\",\"@noink\"],[[28,[37,0],[\"settings.googleAds.label\"],null],[30,0,[\"connectedText\"]],true]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"left\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[30,2,[\"trigger\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],null,[[\"@state\"],[[30,0,[\"googleState\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[30,2,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"isConnected\"]],[[[1,\"          \"],[1,[28,[35,0],[\"settings.oAuth.connectedPopover\"],[[\"user\"],[[30,0,[\"value\",\"name\"]]]]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[28,[35,0],[\"settings.oAuth.disconnectedPopover\"],null]],[1,\"\\n\"]],[]]],[1,\"      \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[41,[51,[30,0,[\"readOnly\"]]],[[[1,\"    \"],[8,[30,1,[\"right\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"flex items-start gap2\"],[12],[1,\"\\n\"],[41,[30,0,[\"isConnected\"]],[[[1,\"          \"],[8,[39,5],null,[[\"@icon\",\"@label\",\"@onClick\"],[\"connect\",[28,[37,0],[\"settings.oAuth.reconnect\"],null],[30,0,[\"connect\"]]]],null],[1,\"\\n          \"],[8,[39,5],null,[[\"@label\",\"@icon\",\"@isDanger\",\"@onClick\"],[[28,[37,0],[\"settings.oAuth.disconnect\"],null],\"disconnect\",true,[30,0,[\"disconnect\"]]]],null],[1,\"\\n          \"],[8,[39,5],null,[[\"@icon\",\"@isTertiary\",\"@onClick\"],[\"info\",true,[30,0,[\"onClick\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"          \"],[8,[39,5],null,[[\"@icon\",\"@label\",\"@onClick\"],[\"connect\",[28,[37,0],[\"settings.oAuth.connect\"],null],[30,0,[\"connect\"]]]],null],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[1]]]]]],[\"Panel\",\"pop\"],false,[\"t\",\"ui-popover\",\"ui-status\",\"if\",\"unless\",\"ui-icon-button\"]]",
    "moduleName": "additive-mi/components/ami-settings/google-settings.hbs",
    "isStrictMode": false
  });
  /**
   * Handles the Google Adwords authentication and settings
   *
   * @class AmcSettingsGoogleSettingsComponent
   */
  let AmcSettingsGoogleSettingsComponent = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.object), _dec2 = (0, _emberArgTypes.arg)(_propTypes.object), _dec3 = (0, _emberArgTypes.arg)(_propTypes.object), _dec4 = (0, _emberArgTypes.arg)(_propTypes.bool), _dec5 = (0, _emberArgTypes.arg)(_propTypes.func), _dec6 = (0, _emberArgTypes.arg)(_propTypes.func), _dec7 = (0, _emberArgTypes.arg)(_propTypes.func), _dec8 = (0, _emberArgTypes.arg)(_propTypes.func), _dec9 = (0, _emberConcurrency.task)(function* (argument) {
    yield this.onChange(argument);
  }), _class = class AmcSettingsGoogleSettingsComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "uiDialog", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "uiToast", _descriptor3, this);
      /**
       * the ui-control-group
       *
       * @argument controlPanelGroup
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "controlPanelGroup", _descriptor4, this);
      /**
       * the google oAuth object
       *
       * @argument value
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "value", _descriptor5, this);
      /**
       * the settings changeset
       *
       * @argument changeset
       * @type {Object}
       * @default null
       */
      _initializerDefineProperty(this, "changeset", _descriptor6, this);
      /**
       * whether the settings are read only
       *
       * @argument readOnly
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "readOnly", _descriptor7, this);
      /**
       * the google apis token client object
       *
       * @property _googleTokenClient
       * @type {Object}
       * @private
       */
      _defineProperty(this, "_googleTokenClient", null);
      /**
       * the callback function that saves the facebook oAuth value
       *
       * @function onChange
       */
      _initializerDefineProperty(this, "onChange", _descriptor8, this);
      /**
       * callback on click
       *
       * @function onClick
       */
      _initializerDefineProperty(this, "onClick", _descriptor9, this);
      /**
       * callback on submit
       *
       * @function onSubmit
       */
      _initializerDefineProperty(this, "onSubmit", _descriptor10, this);
      /**
       * disconnects from Google
       *
       * @function onGoogleDisconnect
       */
      _initializerDefineProperty(this, "onGoogleDisconnect", _descriptor11, this);
      _initializerDefineProperty(this, "onChangeTask", _descriptor12, this);
      const script = document.createElement('script');
      const firstScript = document.getElementsByTagName('script')[0];
      script.async = 1;
      script.defer = 1;
      script.src = 'https://accounts.google.com/gsi/client';
      firstScript.parentNode.insertBefore(script, firstScript);
      script.onload = () => {
        this.setup();
      };
    }
    /**
     * the state of the connection
     *
     * @computed googleState
     * @type {String}
     */
    get googleState() {
      return this.value?.hasUserToken ? 'success' : '';
    }

    /**
     * whether there is a connection
     *
     * @computed isConnected
     * @type {Boolean}
     */
    get isConnected() {
      return this.googleState === 'success';
    }
    get isDisconnected() {
      return !this.args.value.hasUserToken;
    }

    /**
     * Formatted string containing the ad account names
     *
     */
    get selectedAdAccounts() {
      const selectedAccounts = this.changeset?.get('googleAds.adAccounts') || [];
      return selectedAccounts.map(account => account?.label).join(', ');
    }
    get connectedText() {
      if (!this.isConnected) {
        return '';
      }
      const managerAccount = this.changeset?.get('googleAds.managerAccount.label');
      const analyticsAccount = this.changeset?.get('googleAnalytics.account.label');
      return [managerAccount, analyticsAccount].filter(account => account).join(', ');
    }
    /**
     * handles the login over the google oAuth API
     *
     * @function connect
     */
    async connect() {
      try {
        await this._googleTokenClient.requestCode();
      } catch (e) {
        this.uiToast.showToast({
          title: this.intl.t('toast.unexpectedError'),
          type: 'error'
        });
      }
    }
    async setup() {
      try {
        if (!this.value?.clientId) {
          return;
        }
        // eslint-disable-next-line no-undef
        this._googleTokenClient = await google.accounts.oauth2.initCodeClient({
          client_id: this.value?.clientId,
          scope: this.value?.scope,
          ux_mode: 'popup',
          callback: tokenResponse => {
            this.onChangeTask.perform({
              authCode: tokenResponse.code,
              hasUserToken: false
            });
            this.onSubmit();
          }
        });
      } catch (e) {
        this.uiToast.showToast({
          type: 'error',
          title: this.intl.t('global.toast.error.savedChanges')
        });
      }
    }

    /**
     * removes the connection to the google API
     *
     * @function disconnect
     */
    disconnect() {
      this.uiDialog.showConfirm(this.intl.t('settings.oAuth.disconnectDialog.title'), this.intl.t('settings.oAuth.disconnectDialog.description'), () => this.onGoogleDisconnect({
        hasUserToken: false
      }), this.intl.t('global.actions.disconnect'), true);
    }

    /**
     * sets the ad accounts in the changeset
     *
     * @function onAdAccountChange
     * @param {Object} option
     * @param {Boolean} checked
     */
    onAdAccountChange(option, checked) {
      const accounts = [].concat(this.changeset.get('googleAds.adAccounts.value') || []);
      if (checked) {
        if (!accounts.find(account => account.value === option.value)) {
          accounts.pushObject(option);
        }
      } else {
        accounts.removeObject(accounts.find(account => account.value === option.value));
      }
      this.changeset.set('googleAds.adAccounts.value', accounts);
      this.changeset.execute();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "controlPanelGroup", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_dec3, _tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "readOnly", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "onChange", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return () => {};
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "onClick", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return () => {};
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "onSubmit", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return () => {};
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "onGoogleDisconnect", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return () => {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "connect", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "connect"), _class.prototype), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "onChangeTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disconnect", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "disconnect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onAdAccountChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onAdAccountChange"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmcSettingsGoogleSettingsComponent);
});