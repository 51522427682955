define("additive-mi/components/ui-collapsible-table/row/value-column", ["exports", "@additive-apps/ui/components/ui-collapsible-table/row/value-column"], function (_exports, _valueColumn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _valueColumn.default;
    }
  });
});