define("additive-mi/serializers/custom-report", ["exports", "additive-mi/serializers/report"], function (_exports, _report) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CustomReportSerializer extends _report.default {
    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      const _payload = {
        ...payload
      };
      if (_payload?.reports) {
        _payload.customReports = _payload?.reports || [];
        delete _payload.reports;
      }
      if (_payload?.report) {
        _payload.customReport = _payload?.report || {};
        delete _payload.report;
      }
      return super.normalizeResponse(store, primaryModelClass, _payload, id, requestType);
    }
  }
  _exports.default = CustomReportSerializer;
});