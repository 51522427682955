define("additive-mi/mirage/routes/settings/index", ["exports", "additive-mi/mirage/routes/settings/settings", "additive-mi/mirage/routes/settings/report-settings", "additive-mi/mirage/routes/settings/service-cost-settings", "additive-mi/mirage/routes/settings/budget-settings"], function (_exports, _settings, _reportSettings, _serviceCostSettings, _budgetSettings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "BudgetSettingRoutes", {
    enumerable: true,
    get: function () {
      return _budgetSettings.BudgetSettingRoutes;
    }
  });
  Object.defineProperty(_exports, "ReportSettingRoutes", {
    enumerable: true,
    get: function () {
      return _reportSettings.ReportSettingRoutes;
    }
  });
  Object.defineProperty(_exports, "ServiceCostSettingRoutes", {
    enumerable: true,
    get: function () {
      return _serviceCostSettings.ServiceCostSettingRoutes;
    }
  });
  Object.defineProperty(_exports, "SettingRoutes", {
    enumerable: true,
    get: function () {
      return _settings.SettingRoutes;
    }
  });
});