define("additive-mi/adapters/report", ["exports", "additive-mi/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Base adapter for all report types,
   * intended to be extended per type.
   * `type` must be set
   */
  var _default = _exports.default = _application.default.extend({
    type: null,
    urlForMultiple() {
      return this.buildURL() + `/reports/${this.type}`;
    },
    urlForSingle(id, _, snapshot) {
      const action = snapshot?.adapterOptions?.action;
      return `${this.buildURL()}/reports/${this.type}/${id}${action ? `/${action}` : ''}`;
    },
    urlForFindAll() {
      return this.urlForMultiple(...arguments);
    },
    urlForCreateRecord() {
      return this.urlForMultiple(...arguments);
    },
    urlForDeleteRecord() {
      return this.urlForSingle(...arguments);
    },
    urlForFindRecord() {
      return this.urlForSingle(...arguments);
    },
    urlForUpdateRecord() {
      return this.urlForSingle(...arguments);
    },
    urlForQuery() {
      return this.urlForMultiple(...arguments);
    }
  });
});