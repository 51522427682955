define("additive-mi/mirage/routes/report", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ReportsRequest = void 0;
  const ReportsRequest = self => {
    self.get('/reports/:type', (schema, {
      params,
      queryParams: {
        page = 1,
        per_page = 12
      }
    }) => {
      const {
        type
      } = params;
      const reports = schema.reports.where({
        type
      });
      const models = reports.models;
      const perPage = parseInt(per_page);
      return {
        reports: models.slice((page - 1) * perPage, page * perPage).map(model => {
          return {
            id: model.id,
            title: model.title,
            active: model.active,
            reportStart: model.reportStart,
            reportEnd: model.reportEnd
          };
        }),
        meta: {
          currentPage: Number(page),
          from: (page - 1) * perPage + 1,
          lastPage: 1 + Math.floor(models.length / perPage),
          perPage: perPage,
          to: page * perPage,
          total: models.length
        }
      };
    }, {
      timing: 500
    });
    self.get('/reports/:type/upcoming/notices', () => {
      return {
        notices: [{
          key: 'date_range_missing_month_specific_budget',
          severity: 'warning',
          affectedMonths: ['Juni 2024'],
          message: 'In 4 Tagen wird ein neuer Kampagnen-Report erstellt. Da die monatliche Budget-Konfiguration für den Report-Zeitraum nicht vorgenommen wurde, werden an manchen Stellen die Budget-Werte des Vormonats verwendet.',
          action: 'budget'
        }]
      };
    });
    self.get('/reports/:type/:id', (schema, request) => {
      const {
        id
      } = request.params;
      return schema.reports.find(id);
    });
    self.put('/reports/:type/:id/recalculate', (schema, request) => {
      const {
        id
      } = request.params;
      return schema.reports.find(id);
    }, {
      timing: 4000
    });
    self.get('/reports/:type/:id/preview-html', () => {
      return `
    <html>
      <head>
        <style>
          html {
            height: 100%;
          }

          body {
            display: flex;
            width: 100%;
            height: 100%;
            margin: 0;
            justify-content: center;
            align-items: center;
            background: #00263E;
          }

          h1 {
            font-size: 40px;
            line-height: 48px;
            text-align: center;
            color: #fff;
          }
        </style>
      </head>
      <body>
        <h1>Slideshow Preview</h1>
      </body>
    </html>`;
    });
    self.get('/reports/:type/:id/html', () => {
      return `
    <html>
      <head>
        <style>
          body {
            display: flex;
            width: 100%;
            height: 100%;
            margin: 0;
            justify-content: center;
            align-items: center;
            background: #00263E;
          }

          h1 {
            font-size: 40px;
            line-height: 48px;
            text-align: center;
            color: #fff;
          }
        </style>
      </head>
      <body>
        <h1>Slideshow</h1>
      </body>
    </html>`;
    });
    self.put('/reports/:type/:id', (schema, request) => {
      const {
        id
      } = request.params;
      const attrs = JSON.parse(request.requestBody);
      const model = schema.reports.find(id);
      const includeHosts = model.settings.includeHosts;
      includeHosts.value = includeHosts.allowedValues?.filter(allowedValue => attrs.settings?.includeHosts?.includes(allowedValue.value)).map(allowedValue => allowedValue.value);
      attrs.settings.includeHosts = includeHosts;
      return model.update(attrs);
    });
    self.post('/reports/:type/:id/shares', schema => {
      const reportShareLinks = schema.reportShareLinks.all();
      reportShareLinks.models.forEach(model => model.destroy());
      const reportShareLink = self.create('report-share-link');
      return reportShareLink;
    });
    self.get('/reports/:type/:id/shares', schema => {
      return schema.reportShareLinks.all();
    });
    self.delete('/reports/automated/:id/shares/:id', schema => {
      schema.reportShareLinks.all().models[0].destroy();
      return new _emberCliMirage.Response(204);
    });
    self.delete('/reports/automated/:id/notices/:id', () => {
      return new _emberCliMirage.Response(204);
    });
    self.get('/reports/automated/pdfs/:id', () => {
      var blob = new Blob([''], {
        type: 'application/pdf'
      });
      blob['lastModifiedDate'] = '';
      blob['name'] = 'report.pdf';
      var fakePdf = blob;
      return new _emberCliMirage.Response(200, {
        'Content-Type': 'application/octet-stream',
        'Content-Disposition': 'attachment; filename=report.pdf'
      }, fakePdf);
    }, {
      timing: 3000
    });
    self.get('/reports/settings', schema => {
      return schema.reportConfigurations.first();
    });
    self.put('/reports/settings', (schema, request) => {
      const attrs = JSON.parse(request.requestBody);
      const model = schema.reportConfigurations.first();
      return model.update(attrs);
    });
  };
  _exports.ReportsRequest = ReportsRequest;
});