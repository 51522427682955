define("additive-mi/mirage/config", ["exports", "additive-mi/config/environment", "additive-mi/mirage/routes", "additive-mi/mirage/routes/settings"], function (_exports, _environment, _routes, _settings) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.urlPrefix = _environment.default.APP.apiBaseHost;
    (0, _routes.PublicRoutes)(this);
    this.namespace = '**';
    (0, _routes.AnalyticsRequest)(this);
    (0, _routes.AnalyticsDetailRequest)(this);
    (0, _routes.ReportsRequest)(this);
    (0, _routes.ConversionAttributionRequest)(this);
    (0, _routes.ConversionsRequest)(this);
    (0, _routes.EnquiryAttributionRequest)(this);
    (0, _routes.EnquiryAttributionDetailRequest)(this);
    (0, _routes.FiltersRequest)(this);
    (0, _routes.KeyIndicatorsRequest)(this);
    (0, _routes.PerformanceIndicatorsRequest)(this);
    (0, _routes.ReservationsRequest)(this);
    (0, _settings.SettingRoutes)(this);
    (0, _settings.ReportSettingRoutes)(this);
    (0, _settings.ServiceCostSettingRoutes)(this);
    (0, _settings.BudgetSettingRoutes)(this);
    (0, _routes.AlertsRequest)(this);
  }
});