define("additive-mi/mirage/routes/analytics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AnalyticsRequest = void 0;
  const AnalyticsRequest = self => {
    self.get('/analytics', schema => {
      return schema.analytics.first();
    });
  };
  _exports.AnalyticsRequest = AnalyticsRequest;
});