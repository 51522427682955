define("additive-mi/components/ami-upgrade-notification", ["exports", "@ember/component", "@ember/template-factory", "@additive-apps/ui/components/ui-property-watcher", "ember-concurrency", "@glimmer/tracking", "@ember/service", "@additive-apps/ui/constants"], function (_exports, _component, _templateFactory, _uiPropertyWatcher, _emberConcurrency, _tracking, _service, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showNotification}}
    <div class="pb2 pb3-sm pb4-md ph4-sm ph2 mw8 ma-auto mb4">
      <UiUpgradeNotification
        @logo="marketing-cloud"
        @title={{t "dashboard.upgradeMessage.title"}}
        @text={{t "dashboard.upgradeMessage.text"}}
        @buttonText={{t "dashboard.upgradeMessage.buttonText"}}
        @href="https://www.additive-apps.eu/apps/marketing-cloud.html?utm_source=additive_mi&utm_medium=banner&utm_campaign=upgrade"
        @plan={{t "dashboard.upgradeMessage.plan"}}
      />
    </div>
  {{/if}}
  */
  {
    "id": "koqLJnf0",
    "block": "[[[41,[30,0,[\"showNotification\"]],[[[1,\"  \"],[10,0],[14,0,\"pb2 pb3-sm pb4-md ph4-sm ph2 mw8 ma-auto mb4\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@logo\",\"@title\",\"@text\",\"@buttonText\",\"@href\",\"@plan\"],[\"marketing-cloud\",[28,[37,2],[\"dashboard.upgradeMessage.title\"],null],[28,[37,2],[\"dashboard.upgradeMessage.text\"],null],[28,[37,2],[\"dashboard.upgradeMessage.buttonText\"],null],\"https://www.additive-apps.eu/apps/marketing-cloud.html?utm_source=additive_mi&utm_medium=banner&utm_campaign=upgrade\",[28,[37,2],[\"dashboard.upgradeMessage.plan\"],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"ui-upgrade-notification\",\"t\"]]",
    "moduleName": "additive-mi/components/ami-upgrade-notification.hbs",
    "isStrictMode": false
  });
  let AmiUpgradeNotificationComponent = _exports.default = (_dec = (0, _emberConcurrency.task)(function* () {
    try {
      this.showNotification = false;
      const apps = yield this.store.query('app', {
        accessible: 1
      });
      const marketingCloud = apps.find(app => parseInt(app.id) === _constants.APP_ID_MARKETING_CLOUD);
      if (!marketingCloud || !marketingCloud.active) {
        this.showNotification = true;
      }
    } catch (e) {
      this.showNotification = false;
    }
  }), _class = class AmiUpgradeNotificationComponent extends _uiPropertyWatcher.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      /**
       * whether the notification is shown
       *
       * @property showNotification
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "showNotification", _descriptor2, this);
      _defineProperty(this, "_watchedProperties", ['organizationSlug']);
      /**
       * fetches the organizations apps and displays
       * the notification if MC app is not present
       *
       * @function _checkAvailableApps
       * @type {Task}
       */
      _initializerDefineProperty(this, "_checkAvailableApps", _descriptor3, this);
      this._checkAvailableApps.perform();
    }
    _onPropertyChange() {
      this._checkAvailableApps.perform();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showNotification", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_checkAvailableApps", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmiUpgradeNotificationComponent);
});