define("additive-mi/mirage/routes/settings/settings", ["exports", "faker"], function (_exports, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SettingRoutes = void 0;
  const SettingRoutes = self => {
    self.get('/settings', schema => {
      return schema.settings.first();
    });
    self.put('/settings', (schema, request) => {
      const attrs = JSON.parse(request.requestBody);
      if (attrs.facebookOAuth.userId && !attrs.facebookOAuth.userName) {
        attrs.facebookOAuth.userName = _faker.default.name.findName();
      }
      const model = schema.settings.first();
      attrs.includeHosts = {
        value: attrs.includeHosts,
        allowedValues: model.includeHosts.allowedValues
      };
      return model.update(attrs);
    });
  };
  _exports.SettingRoutes = SettingRoutes;
});