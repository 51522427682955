define("additive-mi/models/report", ["exports", "@ember-data/model", "@glimmer/tracking", "@ember/service", "ember-concurrency"], function (_exports, _model, _tracking, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ReportModel = _exports.default = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)(), _dec4 = (0, _model.attr)(), _dec5 = (0, _model.attr)(), _dec6 = (0, _model.attr)(), _dec7 = (0, _model.attr)(), _dec8 = (0, _model.attr)('date'), _dec9 = (0, _model.attr)(), _dec10 = (0, _model.attr)(), _dec11 = (0, _model.attr)('boolean'), _dec12 = (0, _model.attr)('boolean'), _dec13 = (0, _emberConcurrency.task)(function* () {
    const toast = this.uiToast.showToast({
      type: 'info',
      title: this.intl.t('reports.download.toasts.loading.title'),
      description: this.intl.t('reports.download.toasts.loading.description'),
      duration: -1
    });
    try {
      const adapter = this.store.adapterFor(this.type);
      const url = adapter.urlForFindAll();
      const response = yield this.authenticatedFetch.fetch(`${url}/pdfs/${this.id}`, {
        headers: {
          'Content-Type': 'application/pdf'
        }
      });
      if (!response?.ok) {
        throw new Error('Could not fetch preview html');
      }
      const contentDisposition = response.headers.get('Content-Disposition');

      // get filename from content disposition header or use fallback
      const contentDispositionFileNameParts = contentDisposition?.split('filename=');
      let filename = contentDispositionFileNameParts.length > 1 ? contentDispositionFileNameParts[1].split(';')[0] : 'report.pdf';

      // remove leading and trailing quotes
      filename = filename.replace(/"/g, '');
      const blob = yield response.blob();
      const fileUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = filename;
      a.click();
      this._runningPdfTask = null;
      this.uiToast.showToast({
        type: 'success',
        title: this.intl.t('reports.download.toasts.success')
      });
    } catch (error) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('reports.download.toasts.error')
      });
    } finally {
      this.uiToast.destroyToast(toast);
    }
  }).drop(), _dec14 = (0, _emberConcurrency.task)(function* () {
    try {
      const adapter = this.store.adapterFor(this.type);
      const url = adapter.urlForSingle(this.id);
      const response = yield this.authenticatedFetch.fetch(`${url}/preview-html`, {
        headers: {
          'Content-Type': 'text/html'
        }
      });
      if (!response?.ok) {
        throw new Error('Could not fetch preview html');
      }
      this._previewHtml = yield response.text();
      this._runningPreviewTask = null;
      return this._previewHtml;
    } catch (error) {
      // TODO handle error
    }
  }).drop(), _class = class ReportModel extends _model.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _initializerDefineProperty(this, "uiToast", _descriptor4, this);
      _initializerDefineProperty(this, "title", _descriptor5, this);
      _initializerDefineProperty(this, "description", _descriptor6, this);
      _initializerDefineProperty(this, "headerImage", _descriptor7, this);
      _initializerDefineProperty(this, "appliedSettings", _descriptor8, this);
      _initializerDefineProperty(this, "settings", _descriptor9, this);
      _initializerDefineProperty(this, "slides", _descriptor10, this);
      _initializerDefineProperty(this, "notices", _descriptor11, this);
      _initializerDefineProperty(this, "createdAt", _descriptor12, this);
      _initializerDefineProperty(this, "reportStart", _descriptor13, this);
      _initializerDefineProperty(this, "reportEnd", _descriptor14, this);
      _initializerDefineProperty(this, "active", _descriptor15, this);
      _initializerDefineProperty(this, "saveKpiLayout", _descriptor16, this);
      _defineProperty(this, "type", null);
      _initializerDefineProperty(this, "_previewHtml", _descriptor17, this);
      _initializerDefineProperty(this, "_runningPreviewTask", _descriptor18, this);
      _initializerDefineProperty(this, "_runningPdfTask", _descriptor19, this);
      _initializerDefineProperty(this, "_fetchPdf", _descriptor20, this);
      _initializerDefineProperty(this, "_fetchPreviewHtml", _descriptor21, this);
      _defineProperty(this, "getPreviewHtml", async () => {
        if (this._previewHtml) {
          return this._previewHtml;
        }
        if (this._runningPreviewTask?.isRunning) {
          return this._runningPreviewTask;
        }
        this._runningPreviewTask = this._fetchPreviewHtml.perform();
        const previewHtml = await this._runningPreviewTask;
        return previewHtml;
      });
      _defineProperty(this, "fetchPreviewHtml", async () => {
        if (this._runningPreviewTask?.isRunning) {
          return this._runningPreviewTask;
        }
        this._runningPreviewTask = this._fetchPreviewHtml.perform();
        const previewHtml = await this._runningPreviewTask;
        return previewHtml;
      });
      _defineProperty(this, "getPdf", async () => {
        if (this._runningPdfTask?.isRunning) {
          return this._runningPdfTask;
        }
        this._runningPdfTask = this._fetchPdf.perform();
      });
    }
    get formattedReportRange() {
      const reportStart = new Date(this.reportStart.year, this.reportStart.month - 1);
      const reportEnd = new Date(this.reportEnd.year, this.reportEnd.month - 1);
      if (reportStart.getTime() === reportEnd.getTime()) {
        return reportStart.toLocaleString('de-DE', {
          month: 'short',
          year: 'numeric'
        });
      }
      if (this.reportStart.year === this.reportEnd.year) {
        return `${reportStart.toLocaleString('de-DE', {
          month: 'short'
        })} - ${reportEnd.toLocaleString('de-DE', {
          month: 'short',
          year: 'numeric'
        })}`;
      }
      return `${reportStart.toLocaleString('de-DE', {
        month: 'short',
        year: 'numeric'
      })} - ${reportEnd.toLocaleString('de-DE', {
        month: 'short',
        year: 'numeric'
      })}`;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "headerImage", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "appliedSettings", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "slides", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "notices", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "reportStart", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "reportEnd", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "active", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "saveKpiLayout", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "_previewHtml", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "_runningPreviewTask", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "_runningPdfTask", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "_fetchPdf", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "_fetchPreviewHtml", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});