define("additive-mi/mirage/factories/stay-attribution", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    labels() {
      return [{
        key: 'first',
        name: 'A'
      }, {
        key: 'B',
        name: 'B',
        description: 'Ich bin die B, yea!'
      }, {
        key: 'C',
        name: 'C'
      }, {
        key: 'D',
        name: 'D'
      }, {
        key: 'E',
        name: 'E'
      }, {
        key: 'F',
        name: 'F'
      }, {
        key: 'G',
        name: 'G'
      }, {
        key: 'H',
        name: 'H'
      }, {
        key: 'I',
        name: 'I'
      }, {
        key: 'J',
        name: 'J'
      }, {
        key: 'K',
        name: 'K'
      }];
    },
    afterCreate(model, server) {
      const columnCount = model.labels.length;
      const data = server.createList('stay-attribution-sub', 10, {
        columnCount
      });
      let newData = [];
      data.forEach(sub => {
        /* Serialize each sub-data Model */
        newData.push(sub.toJSON());
      });
      model.update({
        data: newData,
        columnCount
      });
    }
  });
});