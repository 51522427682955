define("additive-mi/components/ui-upgrade-notification", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ui-upgrade-notification bg-blue pa3 pa4-sm br1 flex-cloumn-sm flex-md items-center justify-between"
  >
    <div class="flex pr2 flex-shrink-0 items-center relative">
      <div class="relative pv1">
        {{#if @plan}}
          <div
            class="ui-upgrade-notification__plan-badge absolute top-0 right-0 bg-white font-xs ph05 blue shadow-1"
          >
            {{@plan}}
          </div>
        {{/if}}
        {{svg-jar @logo class="h3"}}
      </div>
  
      <div class="pl2 font-lg white">
        {{@title}}
      </div>
    </div>
  
    <div class="pt1 pt0-md font-md white">
      {{@text}}
    </div>
  
    <UiButton
      @href={{@href}}
      @label={{@buttonText}}
      @target="_blank"
      class="mt2 mt0-md bg-white blue flex-shrink-0"
    />
  </div>
  */
  {
    "id": "NvmZuR39",
    "block": "[[[10,0],[14,0,\"ui-upgrade-notification bg-blue pa3 pa4-sm br1 flex-cloumn-sm flex-md items-center justify-between\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex pr2 flex-shrink-0 items-center relative\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"relative pv1\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[10,0],[14,0,\"ui-upgrade-notification__plan-badge absolute top-0 right-0 bg-white font-xs ph05 blue shadow-1\"],[12],[1,\"\\n          \"],[1,[30,1]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[1,[28,[35,1],[[30,2]],[[\"class\"],[\"h3\"]]]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"pl2 font-lg white\"],[12],[1,\"\\n      \"],[1,[30,3]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"pt1 pt0-md font-md white\"],[12],[1,\"\\n    \"],[1,[30,4]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,2],[[24,0,\"mt2 mt0-md bg-white blue flex-shrink-0\"]],[[\"@href\",\"@label\",\"@target\"],[[30,5],[30,6],\"_blank\"]],null],[1,\"\\n\"],[13]],[\"@plan\",\"@logo\",\"@title\",\"@text\",\"@href\",\"@buttonText\"],false,[\"if\",\"svg-jar\",\"ui-button\"]]",
    "moduleName": "additive-mi/components/ui-upgrade-notification.hbs",
    "isStrictMode": false
  });
  class UiUpgradeNotificationComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "plan", null);
      _defineProperty(this, "href", null);
      _defineProperty(this, "title", null);
      _defineProperty(this, "text", null);
      _defineProperty(this, "logo", null);
      _defineProperty(this, "buttonText", null);
    }
  }
  _exports.default = UiUpgradeNotificationComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, UiUpgradeNotificationComponent);
});