define("additive-mi/components/mm-media-gallery/header-right", ["exports", "@additive-apps/media/components/mm-media-gallery/header-right"], function (_exports, _headerRight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _headerRight.default;
    }
  });
});