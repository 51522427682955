define("additive-mi/validations/settings", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.temporalSettingValidation = _exports.settingsValidation = _exports.reportSettingsValidation = void 0;
  const EMAIL_FORMAT = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  const RATE_REGEX = /^\d+:\d+$/;
  const settingsValidation = (intl, allowAttributionsBlank = true) => {
    return {
      conversionRateRequestsToReservations: (0, _validators.validateFormat)({
        regex: RATE_REGEX,
        allowBlank: allowAttributionsBlank,
        message: intl.t(allowAttributionsBlank ? 'global.errors.rate' : 'global.errors.requiredRate')
      }),
      averageReservationRevenue: (0, _validators.validateNumber)({
        gt: 0,
        allowBlank: allowAttributionsBlank,
        integer: true,
        message: intl.t(allowAttributionsBlank ? 'global.errors.positiveInteger' : 'global.errors.requiredPositiveInteger')
      })
    };
  };
  _exports.settingsValidation = settingsValidation;
  const reportSettingsValidation = intl => {
    return {
      generationDay: (0, _validators.validateNumber)({
        gt: 0,
        lt: 29,
        integer: true,
        message: intl.t('settings.reports.generationDay.error')
      }),
      emailReceivers: (_key, value) => {
        let isValid = true;
        const emails = value;
        emails?.forEach(email => {
          if (!EMAIL_FORMAT.test(email)) {
            isValid = false;
          }
        });
        return isValid || intl.t('global.errors.email');
      }
    };
  };
  _exports.reportSettingsValidation = reportSettingsValidation;
  const temporalSettingValidation = (intl, model) => {
    const countMessageOrZero = intl.t('global.errors.positiveFloatOrZero');
    const presenceMessage = intl.t('global.errors.required');
    let validation = {
      value: (0, _validators.validateNumber)({
        gte: 0,
        message: countMessageOrZero
      }),
      startsAt: (0, _validators.validatePresence)({
        presence: true,
        message: presenceMessage
      })
    };
    if (model === 'budgets') {
      validation = Object.assign(validation, {
        surcharge: (0, _validators.validateNumber)({
          gte: 0,
          message: countMessageOrZero
        })
      });
    }
    return validation;
  };
  _exports.temporalSettingValidation = temporalSettingValidation;
});