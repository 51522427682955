define("additive-mi/mirage/routes/enquiry-attribution", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EnquiryAttributionRequest = void 0;
  const EnquiryAttributionRequest = self => {
    self.get('/enquiry-attribution/date', schema => {
      return schema.enquiryAttributions.first();
    });
    self.get('/enquiry-attribution/stay', schema => {
      return schema.enquiryAttributions.first();
    });
  };
  _exports.EnquiryAttributionRequest = EnquiryAttributionRequest;
});