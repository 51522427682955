define("additive-mi/helpers/array-tail", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayTail = arrayTail;
  _exports.default = void 0;
  function arrayTail([array]) {
    return array.slice(1);
  }
  var _default = _exports.default = (0, _helper.helper)(arrayTail);
});