define("additive-mi/mirage/routes/conversion-attribution", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ConversionAttributionRequest = void 0;
  const ConversionAttributionRequest = self => {
    self.get('/conversion-attribution', schema => {
      return schema.conversionAttributions.first();
    });
    self.get('/conversion-attribution/filters', () => {
      return {
        attributionModes: [{
          value: 'last_interaction',
          label: 'Letzte Interaktion'
        }, {
          value: 'first_interaction',
          label: 'Erste Interaktion'
        }, {
          value: 'position_based',
          label: 'Positionsbasiert'
        }, {
          value: 'linear',
          label: 'linear'
        }, {
          value: 'time_decay',
          label: 'Zeitbegrenzt'
        }],
        conversionTypes: [{
          value: 'all',
          label: 'Alle'
        }, {
          value: 'request,booking',
          label: 'Anfragen + Buchungen'
        }, {
          value: 'request',
          label: 'Anfragen'
        }, {
          value: 'booking',
          label: 'Buchungen'
        }, {
          value: 'voucher',
          label: 'Gutscheinkäufe'
        }],
        groups: [{
          code: 'ENTRY_HOST_GOOGLE_TRANSLATE',
          label: '[translate.google.com]'
        }, {
          code: 'SOURCE_FACEBOOK_COM',
          label: '[facebook.com]'
        }, {
          code: 'SOURCE_GOOGLE_COM',
          label: '[google.com]'
        }, {
          code: 'SOURCE_TRIVAGO_COM',
          label: '[trivago.com]'
        }, {
          code: 'SOURCE_PINTEREST_COM',
          label: '[pinterest.com]'
        }, {
          code: 'SOURCE_TRIPADVISOR_COM',
          label: '[tripadvisor.com]'
        }, {
          code: 'SOURCE_INSTAGRAM_COM',
          label: '[instagram.com]'
        }, {
          code: 'SOURCE_BING_COM',
          label: '[bing.com]'
        }]
      };
    });
  };
  _exports.ConversionAttributionRequest = ConversionAttributionRequest;
});