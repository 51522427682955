define("additive-mi/mirage/routes/analytics/detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AnalyticsDetailRequest = void 0;
  const AnalyticsDetailRequest = self => {
    self.get('/analytics/:id', (schema, request) => {
      const {
        start,
        end
      } = request.queryParams;
      const startDate = new Date(start);
      const endDate = new Date(end);
      return {
        parents: ['Landingpage', 'Deep'],
        title: 'Organische Suche',
        start: startDate.toISOString(),
        end: endDate.toISOString()
      };
    });
    self.get('/analytics/:id/chart', schema => {
      return {
        chart: schema.enquiryAttributionDetailCharts.findBy({
          parent: 1
        }).toJSON()
      };
    });
    self.get('/analytics/:id/table', schema => {
      return {
        table: schema.enquiryAttributionDetailTables.findBy({
          parent: 1
        }).toJSON()
      };
    });
    self.get('/analytics/filters', () => {
      return {
        groups: [{
          code: 'ENTRY_HOST_GOOGLE_TRANSLATE',
          label: '[translate.google.com]'
        }, {
          code: 'SOURCE_FACEBOOK_COM',
          label: '[facebook.com]'
        }, {
          code: 'SOURCE_GOOGLE_COM',
          label: '[google.com]'
        }, {
          code: 'SOURCE_TRIVAGO_COM',
          label: '[trivago.com]'
        }, {
          code: 'SOURCE_PINTEREST_COM',
          label: '[pinterest.com]'
        }, {
          code: 'SOURCE_TRIPADVISOR_COM',
          label: '[tripadvisor.com]'
        }, {
          code: 'SOURCE_INSTAGRAM_COM',
          label: '[instagram.com]'
        }, {
          code: 'SOURCE_BING_COM',
          label: '[bing.com]'
        }]
      };
    });
  };
  _exports.AnalyticsDetailRequest = AnalyticsDetailRequest;
});