define("additive-mi/mirage/routes/filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FiltersRequest = void 0;
  const FiltersRequest = self => {
    self.get('/filters', (_, req) => {
      const orgId = req.params['*'];
      return {
        hosts: [{
          code: 'landingpage',
          label: orgId
        }, {
          code: 'website',
          label: `1-${orgId}`
        }]
      };
    });
    self.get('/enquiry-attribution/date/filters', (_, req) => {
      const orgId = req.params['*'];
      return {
        hosts: [{
          code: 'landingpage',
          label: orgId
        }, {
          code: 'website',
          label: `1-${orgId}`
        }]
      };
    });
    self.get('/enquiry-attribution/stay/filters', (_, req) => {
      const orgId = req.params['*'];
      return {
        hosts: [{
          code: 'landingpage',
          label: orgId
        }, {
          code: 'website',
          label: `1-${orgId}`
        }]
      };
    });
  };
  _exports.FiltersRequest = FiltersRequest;
});