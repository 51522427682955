define("additive-mi/mirage/scenarios/organization", ["exports", "additive-mi/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.starter = _exports.professional = _exports.enterprise = void 0;
  /* TODO: move me into an addon */
  const DEFAULTS = {
    contentLanguages: ['de', 'en', 'it'],
    defaultLanguage: 'de'
  };
  const accountApiRequests = (server, orgSlug) => {
    server.get(`${_environment.default.APP.accountApiHost}/api/organizations`, schema => {
      return schema.organizations.all();
    });
    server.get(`${_environment.default.APP.accountApiHost}/api/organizations/${orgSlug}`, schema => {
      return schema.organizations.findBy({
        id: orgSlug
      });
    });
    server.get(`${_environment.default.APP.accountApiHost}/api/organizations/${orgSlug}/apps`, schema => {
      return schema.organizations.findBy({
        id: orgSlug
      });
    });
  };
  const starter = (server, orgSlug = 'test-org') => {
    server.get(`${server.urlPrefix}/${orgSlug}`, () => {
      return {
        organization: Object.assign({}, DEFAULTS, {
          planName: 'starter_yearly'
        })
      };
    });
    accountApiRequests(server, orgSlug);
  };
  _exports.starter = starter;
  const professional = (server, orgSlug = 'test-org') => {
    server.get(`${server.urlPrefix}/${orgSlug}`, () => {
      return {
        organization: Object.assign({}, DEFAULTS, {
          planName: 'professional_yearly'
        })
      };
    });
    accountApiRequests(server, orgSlug);
  };
  _exports.professional = professional;
  const enterprise = (server, orgSlug = 'test-org') => {
    server.get(`${server.urlPrefix}/${orgSlug}`, () => {
      return {
        organization: Object.assign({}, DEFAULTS, {
          planName: 'enterprise_yearly'
        })
      };
    });
    accountApiRequests(server, orgSlug);
  };
  _exports.enterprise = enterprise;
});