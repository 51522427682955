define("additive-mi/controllers/instance/analytics/detail", ["exports", "@ember/controller", "@glimmer/tracking"], function (_exports, _controller, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceAnalyticsDetailController = _exports.default = (_class = class InstanceAnalyticsDetailController extends _controller.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "queryParams", ['start', 'end', 'groupingHierarchy', 'reservationTypes', 'groups', 'sessionBasis']);
      /**
       * the id of the current analytics
       *
       * @argument detailId
       * @type {String}
       * @default null
       */
      _initializerDefineProperty(this, "detailId", _descriptor, this);
      /**
       * the session basis
       *
       * @argument sessionBasis
       * @type {String}
       * @default 'cookies'
       */
      _initializerDefineProperty(this, "sessionBasis", _descriptor2, this);
      /**
       * the end date filter
       *
       * @argument end
       * @type {String}
       * @default null
       */
      _defineProperty(this, "end", null);
      /**
       * the grouping hierarchy filter
       *
       * @argument groupingHierarchy
       * @type {String}
       * @default null
       */
      _defineProperty(this, "groupingHierarchy", null);
      /**
       * the groups filter
       *
       * @argument groups
       * @type {String}
       * @default null
       */
      _defineProperty(this, "groups", null);
      /**
       * the reservation types filter
       *
       * @argument reservationTypes
       * @type {String}
       * @default null
       */
      _defineProperty(this, "reservationTypes", null);
      /**
       * the start date filter
       *
       * @argument start
       * @type {String}
       * @default null
       */
      _defineProperty(this, "start", null);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "detailId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sessionBasis", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'cookies';
    }
  }), _class);
});