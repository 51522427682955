define("additive-mi/adapters/automated-report", ["exports", "additive-mi/adapters/report"], function (_exports, _report) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _report.default.extend({
    type: 'automated'
  });
});