define("additive-mi/routes/page-not-found", ["exports", "@ember/routing/route", "@additive-apps/ui/mixins/ui-full-width-view"], function (_exports, _route, _uiFullWidthView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line ember/no-mixins

  class PageNotFoundRoute extends _route.default.extend(_uiFullWidthView.default) {}
  _exports.default = PageNotFoundRoute;
});