define("additive-mi/components/mm-file-upload-progress", ["exports", "@additive-apps/multimedia-engine/components/mm-file-upload-progress"], function (_exports, _mmFileUploadProgress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _mmFileUploadProgress.default;
    }
  });
});