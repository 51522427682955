define("additive-mi/routes/reports", ["exports", "additive-mi/config/environment", "@ember/routing/route", "@additive-apps/ui/mixins/ui-full-width-view"], function (_exports, _environment, _route, _uiFullWidthView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line ember/no-mixins

  class ReportsRoute extends _route.default.extend(_uiFullWidthView.default) {
    async model(params) {
      const response = await fetch(`${_environment.default.APP.apiBaseHost}/shared-reports/${params.id}`, {
        headers: {
          'Content-Type': 'text/html'
        }
      });
      if (!response?.ok) {
        // TODO handle error
        return;
      }
      const html = await response.text();
      return html;
    }
    renderTemplate() {
      this.render('reports', {
        into: 'application'
      });
    }
  }
  _exports.default = ReportsRoute;
});