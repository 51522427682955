define("additive-mi/mirage/routes/public-routes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PublicRoutes = void 0;
  const PublicRoutes = self => {
    self.get('/shared-reports/:id', () => {
      return `
  <html>
    <head>
      <style>
        body {
          display: flex;
          width: 100%;
          height: 100%;
          margin: 0;
          justify-content: center;
          align-items: center;
          background: #00263E;
        }

        h1 {
          font-size: 40px;
          line-height: 48px;
          text-align: center;
          color: #fff;
        }
      </style>
    </head>
    <body>
      <h1>Slideshow</h1>
    </body>
  </html>`;
    });
  };
  _exports.PublicRoutes = PublicRoutes;
});