define("additive-mi/components/ami-reports/slideshow", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-arg-types", "prop-types", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _emberArgTypes, _propTypes, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="w-100 h-100" {{did-insert this.setup}}>
  </div>
  */
  {
    "id": "1hOl9ZZ7",
    "block": "[[[11,0],[24,0,\"w-100 h-100\"],[4,[38,0],[[30,0,[\"setup\"]]],null],[12],[1,\"\\n\"],[13]],[],false,[\"did-insert\"]]",
    "moduleName": "additive-mi/components/ami-reports/slideshow.hbs",
    "isStrictMode": false
  });
  /**
   * This component renders the slideshow for a report.
   *
   * @class ami-reports/slideshow
   */
  let AmiReportsSlideshow = _exports.default = (_dec = (0, _emberArgTypes.arg)(_propTypes.string), _class = class AmiReportsSlideshow extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "html", _descriptor, this);
    }
    async setup(element) {
      // prepare ember app for the new content
      document.documentElement.setAttribute('class', 'slideshow-view');

      // add the new content to the DOM
      const parser = new DOMParser();
      let html = parser.parseFromString(this.html, 'text/html');
      const scriptTags = [...html?.getElementsByTagName('script')];
      const div = [...html.body.children].filter(child => child.tagName === 'DIV')[0];
      const linkTags = html?.head.getElementsByTagName('link');
      for (const linkTag of linkTags) {
        const link = document.createElement('link');
        link.href = linkTag.href;
        link.rel = linkTag.rel;
        element.append(link);
      }
      const styleTags = html?.head.getElementsByTagName('style');
      for (const styleTag of styleTags) {
        element.append(styleTag);
      }
      const bodyStyleTags = html?.body.getElementsByTagName('style');
      for (const styleTag of bodyStyleTags) {
        html?.body.removeChild(styleTag);
        styleTag.innerHTML = styleTag.innerHTML.replace('w-1.5', 'w-1\\.5');
        styleTag.innerHTML = styleTag.innerHTML.replace('h-0.5', 'h-0\\.5');
        styleTag.innerHTML = styleTag.innerHTML.replace('h-1.5', 'h-1\\.5');
        styleTag.innerHTML = styleTag.innerHTML.replace(/md:/g, 'md\\:');
        styleTag.innerHTML = styleTag.innerHTML.replace(/lg:/g, 'lg\\:');
        styleTag.innerHTML = styleTag.innerHTML.replace(/xl:/g, 'xl\\:');
        element.append(styleTag);
      }
      element.append(div);
      for (let i = 0; i < scriptTags.length; i++) {
        // hack to trigger loading of scripts as they don't load otherwise
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.defer = false;
        script.async = false;
        if (scriptTags[i].innerHTML) {
          script.innerHTML = scriptTags[i].innerHTML;
          element.append(script);
        } else {
          script.src = scriptTags[i].src;
          const promise = new Promise(resolve => {
            script.addEventListener('load', () => {
              resolve();
            });
          });
          element.append(script);
          await promise;
        }
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "html", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmiReportsSlideshow);
});