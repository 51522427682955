define("additive-mi/mirage/factories/service-cost", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    value: () => _faker.default.random.number({
      min: 10000
    }),
    format: 'currency_cent',
    currency: 'EUR',
    note: () => _faker.default.lorem.words(10),
    recent: (0, _emberCliMirage.trait)({
      startsAt: () => {
        const date = new Date();
        date.setDate(1);
        return date;
      }
    }),
    past: (0, _emberCliMirage.trait)({
      startsAt: () => {
        const today = new Date();
        const date = _faker.default.date.past();
        date.setDate(1);
        if (date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()) {
          date.setMonth(date.getMonth() - 1);
        }
        return date;
      }
    })
  });
});