define("additive-mi/routes/instance/stay-attribution/enquiry", ["exports", "@ember/routing/route", "@ember/service", "additive-mi/utils/constants", "@additive-apps/ui/utils/dom-util"], function (_exports, _route, _service, _constants, _domUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceStayAttributionEnquiryRoute = _exports.default = (_class = class InstanceStayAttributionEnquiryRoute extends _route.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "amiLocalStorage", _descriptor, this);
    }
    async setupController(controller, model, transition) {
      super.setupController(controller, model, transition);
      let queryParams = ['start', 'end', 'dateFilter'];

      // get date filter from query params
      const urlParams = new URLSearchParams(window.location.search);
      const start = urlParams.get('start');
      const end = urlParams.get('end');
      const dateFilter = urlParams.get('dateFilter');
      if (!localStorage.getItem(_constants.LOCAL_STORAGE_FILTER_KEY)) localStorage.setItem(_constants.LOCAL_STORAGE_FILTER_KEY, JSON.stringify({}));
      const localStorageDefault = this.amiLocalStorage.getStoredValue(_constants.LOCAL_STORAGE_FILTER_KEY);
      if (start && end || localStorageDefault?.start && localStorageDefault?.end && localStorageDefault?.dateFilter === 'custom_date') {
        localStorageDefault.dateFilter = 'custom_date';
        localStorageDefault.start = start || localStorageDefault.start;
        localStorageDefault.end = end || localStorageDefault.end;
        queryParams = queryParams.filter(queryParam => queryParam !== 'dateFilter');
      } else if (dateFilter || localStorageDefault.dateFilter) {
        localStorageDefault.dateFilter = dateFilter || localStorageDefault.dateFilter;
        queryParams = queryParams.filter(queryParam => queryParam !== 'start' && queryParam !== 'end');
      }
      this.amiLocalStorage.setValue(_constants.LOCAL_STORAGE_FILTER_KEY, localStorageDefault);
      this.amiLocalStorage.setQueryParams(queryParams);

      // TODO: find better solution -> ensure update of query params in URL on route change
      if (transition?.from) {
        await (0, _domUtil.nextTick)();
        this.amiLocalStorage.setQueryParams([]);
        this.amiLocalStorage.setQueryParams(queryParams);
      }
    }
    resetController() {
      super.resetController(...arguments);
      this.amiLocalStorage.setQueryParams([]);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "amiLocalStorage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});