define("additive-mi/controllers/instance/reports/automated", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-concurrency"], function (_exports, _controller, _object, _service, _tracking, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceReportsAutomatedController = _exports.default = (_dec = (0, _emberConcurrency.task)(function* () {
    if (!this._isPartnerUser || this.currentUser.isViewer) return;
    try {
      const adapter = this.store.adapterFor('automated-report');
      const url = adapter.buildURL() + '/reports/automated/upcoming/notices';
      const response = yield this.authenticatedFetch.fetch(url);
      if (!response?.ok) throw response;
      const {
        notices
      } = yield response.json();
      this.notices = notices.map(notice => {
        return {
          ...notice,
          buttonText: this.intl.t(`reports.notices.errors.${notice.action}.action`)
        };
      });
    } catch (e) {
      this.uiToast.showToast({
        type: 'error',
        title: this.intl.t('global.toast.error.savedChanges')
      });
    }
  }), _class = class InstanceReportsAutomatedController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "store", _descriptor5, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor6, this);
      _initializerDefineProperty(this, "uiToast", _descriptor7, this);
      _initializerDefineProperty(this, "showBudgetWarning", _descriptor8, this);
      _initializerDefineProperty(this, "notices", _descriptor9, this);
      _initializerDefineProperty(this, "fetchNotices", _descriptor10, this);
    }
    get isPreviewOpen() {
      return this.router.currentRouteName === 'instance.reports.automated.detail.index' && (this.currentUser?.isAdditiveUser || this.currentUser?.isPartnerUser);
    }
    get _isPartnerUser() {
      return this.currentUser.isPartnerUser || this.currentUser.isAdditiveUser && this.uiAppSettings.managedByAdditive;
    }
    openPreview(report) {
      this.router.transitionTo('instance.reports.automated.detail', report.id);
    }
    transitionToSettings(type) {
      this.router.transitionTo(`instance.settings.${type === 'report_generation_day_setting' ? 'reports' : type}`);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showBudgetWarning", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "notices", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "openPreview", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openPreview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "transitionToSettings", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "transitionToSettings"), _class.prototype), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "fetchNotices", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});