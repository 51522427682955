define("additive-mi/routes/instance/enquiry-attribution/index", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AttributionIndexRoute extends _route.default {
    redirect() {
      this.transitionTo('instance.enquiry-attribution.date.index');
    }
  }
  _exports.default = AttributionIndexRoute;
});