define("additive-mi/controllers/instance/settings/service-costs", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-concurrency"], function (_exports, _controller, _object, _service, _tracking, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceSettingsServiceCosts = _exports.default = (_dec = (0, _emberConcurrency.task)(function* () {
    this.isError = false;
    try {
      const adapter = this.store.adapterFor('service-cost');
      const url = adapter.buildURL('service-cost');
      const response = yield this.authenticatedFetch.fetch(url);
      if (!response || !response.ok) throw new Error('[SERVICE-COST-SETTINGS] fetch error');
      const {
        serviceCosts
      } = yield response.json();
      const serviceCostSerializer = this.store.serializerFor('service-cost');

      // push records into store in order to use model actions
      Object.keys(serviceCosts).forEach(key => {
        if (!serviceCosts[key]) return;
        const serviceCostGroup = Array.isArray(serviceCosts[key]) ? serviceCosts[key] : [serviceCosts[key]];
        serviceCosts[key] = serviceCostGroup.map(serviceCost => {
          const peekedServiceCost = this.store.peekRecord('service-cost', serviceCost.id);
          if (peekedServiceCost) return peekedServiceCost;

          // normalize service cost and add it to the store
          const normalizedServiceCost = serviceCostSerializer.normalizeResponse(this.store, this.store.modelFor('service-cost'), {
            serviceCost
          }, serviceCost.id, 'findRecord');
          return this.store.push(normalizedServiceCost);
        });
      });
      this.serviceCosts = serviceCosts;
    } catch (error) {
      this.isError = true;
    }
  }), _class = class InstanceSettingsServiceCosts extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor5, this);
      _initializerDefineProperty(this, "uiDialog", _descriptor6, this);
      _initializerDefineProperty(this, "uiToast", _descriptor7, this);
      _initializerDefineProperty(this, "uiPaths", _descriptor8, this);
      /**
       * the service costs settings
       *
       * @property serviceCosts
       * @type {Array}
       * @default null
       */
      _initializerDefineProperty(this, "serviceCosts", _descriptor9, this);
      /**
       * whether an error occured while fetching the service costs
       *
       * @property isError
       * @type {Boolean}
       * @default false
       */
      _initializerDefineProperty(this, "isError", _descriptor10, this);
      _initializerDefineProperty(this, "_newServiceCost", _descriptor11, this);
      _initializerDefineProperty(this, "_fabButtonContainer", _descriptor12, this);
      /**
       * fetches the service costs
       *
       * @function fetchServiceCosts
       * @type {Task}
       */
      _initializerDefineProperty(this, "fetchServiceCosts", _descriptor13, this);
    }
    /**
     * the active service cost
     *
     * @property activeServiceCost
     * @type {Number}
     * @default 0
     */
    get activeServiceCost() {
      return this.serviceCosts?.active?.reduce((acc, serviceCost) => acc + serviceCost.value, 0) ?? 0;
    }
    get _readOnly() {
      // if not managed, only managers and admins can edit
      if (!this.uiAppSettings?.managed) {
        return !this.currentUser.isManager;
      }

      // if managed by partner, only additive and partner users can edit
      if (this.uiAppSettings?.managedBy === 'parnter') {
        return !this.currentUser.isAdditiveUser && !this.currentUser.isPartnerUser;
      }

      // if managed by additive, only additive users can edit
      return !this.currentUser.isAdditiveUser;
    }
    setup() {
      this._fabButtonContainer = document.getElementById('settings-fab-button-container');
    }
    openDetailDialog() {
      const startsAt = new Date();
      startsAt.setHours(12);
      startsAt.setDate(1);
      this._newServiceCost = this.store.createRecord('service-cost', {
        startsAt
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiDialog", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "uiToast", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "uiPaths", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "serviceCosts", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "isError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "_newServiceCost", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "_fabButtonContainer", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "fetchServiceCosts", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openDetailDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openDetailDialog"), _class.prototype), _class);
});