define("additive-mi/templates/instance/reports/automated/detail/slideshow", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Z8wWGeBO",
    "block": "[[[8,[39,0],null,[[\"@html\"],[[30,0,[\"model\"]]]],null]],[],false,[\"ami-reports/slideshow\"]]",
    "moduleName": "additive-mi/templates/instance/reports/automated/detail/slideshow.hbs",
    "isStrictMode": false
  });
});