define("additive-mi/mirage/factories/enquiry-attribution", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    labels() {
      return ['Kanal', 'Ad Spend', 'Ad Revenue', 'ROAS', 'Anfragen', 'Reservierungen', 'Neue Reservierer', 'Ratio', 'Ankünfte', 'Nächtigungen', 'Res. Umsatz', '∅ AD*', '∅ GG*', '∅ BV*', '∅ RU*'];
    },
    afterCreate(model, server) {
      const columnCount = model.labels.length;
      const data = server.createList('enquiry-attribution-sub', 10, {
        columnCount
      });
      let newData = [];
      data.forEach(sub => {
        /* Serialize each sub-data Model */
        newData.push(sub.toJSON());
      });
      model.update({
        data: newData,
        columnCount
      });
    }
  });
});