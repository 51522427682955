define("additive-mi/services/ui-locale", ["exports", "@additive-apps/utils/services/ui-locale"], function (_exports, _uiLocale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _uiLocale.default;
    }
  });
});