define("additive-mi/mirage/routes/enquiry-attribution/detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TableHandler = _exports.EnquiryAttributionDetailRequest = _exports.DetailHandler = _exports.ChartHandler = void 0;
  const EnquiryAttributionDetailRequest = self => {
    self.get('/enquiry-attribution/date/:id', (schema, request) => {
      const {
        start,
        end
      } = request.queryParams;
      const startDate = new Date(start);
      const endDate = new Date(end);
      return {
        parents: ['Landingpage', 'Deep'],
        title: 'Organische Suche',
        start: startDate.toISOString(),
        end: endDate.toISOString()
      };
    });
    self.get('/enquiry-attribution/date/:id/chart', (schema, request) => {
      const {
        id
      } = request.params;
      return {
        chart: schema.enquiryAttributionDetailCharts.findBy({
          parent: id
        }).toJSON()
      };
    });
    self.get('/enquiry-attribution/date/:id/table', (schema, request) => {
      const {
        id
      } = request.params;
      return {
        table: schema.enquiryAttributionDetailTables.findBy({
          parent: id
        }).toJSON()
      };
    });
    self.get('/enquiry-attribution/date/filters', () => {
      return {
        hosts: [{
          code: 'landingpage',
          label: 'Landingpage'
        }, {
          code: 'website',
          label: 'Webseite'
        }]
      };
    });
    self.get('/enquiry-attribution/stay/:id', (schema, request) => {
      const {
        start,
        end
      } = request.queryParams;
      const startDate = new Date(start);
      const endDate = new Date(end);
      return {
        parents: ['Landingpage'],
        title: 'Organische Suche',
        start: startDate.toISOString(),
        end: endDate.toISOString()
      };
    });
    self.get('/enquiry-attribution/stay/:id/chart', (schema, request) => {
      const {
        id
      } = request.params;
      return {
        chart: schema.enquiryAttributionDetailCharts.findBy({
          parent: id
        }).toJSON()
      };
    });
    self.get('/enquiry-attribution/stay/:id/table', (schema, request) => {
      const {
        id
      } = request.params;
      return {
        table: schema.enquiryAttributionDetailTables.findBy({
          parent: id
        }).toJSON()
      };
    });
    self.get('/enquiry-attribution/stay/filters', () => {
      return {
        hosts: [{
          code: 'landingpage',
          label: 'Landingpage'
        }, {
          code: 'website',
          label: 'Webseite'
        }]
      };
    });
  };
  _exports.EnquiryAttributionDetailRequest = EnquiryAttributionDetailRequest;
  const DetailHandler = (schema, request) => {
    const {
      start,
      end
    } = request.queryParams;
    const startDate = start && new Date(start);
    const endDate = end && new Date(end);
    return {
      parents: ['Landingpage', 'Deep'],
      title: 'Organische Suche',
      start: startDate && startDate.toISOString(),
      end: endDate && endDate.toISOString()
    };
  };
  _exports.DetailHandler = DetailHandler;
  const ChartHandler = (schema, request) => {
    const {
      id
    } = request.params;
    return {
      chart: schema.enquiryAttributionDetailCharts.findBy({
        parent: id
      }).toJSON()
    };
  };
  _exports.ChartHandler = ChartHandler;
  const TableHandler = (schema, request) => {
    const {
      id
    } = request.params;
    return {
      table: schema.enquiryAttributionDetailTables.findBy({
        parent: id
      }).toJSON()
    };
  };
  _exports.TableHandler = TableHandler;
});