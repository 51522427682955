define("additive-mi/helpers/ami-object-to-query-params", ["exports", "@ember/component/helper", "@ember/array", "lodash.clonedeep"], function (_exports, _helper, _array, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.amiObjectToQueryParams = void 0;
  const amiObjectToQueryParams = (params, hash) => {
    if (!params || !Array.isArray(params) || params.length === 0) {
      return '';
    }
    const [input] = params;
    if (!input || typeof input !== 'object') {
      return '';
    }
    let filters = (0, _lodash.default)(input);
    Object.keys(filters).forEach(key => {
      const paramValue = filters[key];
      if (!paramValue || (0, _array.isArray)(paramValue) && paramValue.length === 0) {
        delete filters[key];
      }
    });
    if (hash && hash.ignore) {
      delete filters[hash.ignore];
    }
    const urlSearchParams = new URLSearchParams(filters);
    return urlSearchParams.toString();
  };
  _exports.amiObjectToQueryParams = amiObjectToQueryParams;
  var _default = _exports.default = (0, _helper.helper)(amiObjectToQueryParams);
});