define("additive-mi/router", ["exports", "@ember/routing/router", "additive-mi/config/environment", "@additive-apps/auth/router"], function (_exports, _router, _environment, _router2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class Router extends _router.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "location", _environment.default.locationType);
      _defineProperty(this, "rootURL", _environment.default.rootURL);
    }
  }
  _exports.default = Router;
  Router.map(function () {
    this.route('auth', function () {
      (0, _router2.default)(this);
    });
    this.route('reports', {
      path: 'reports/:id'
    });
    this.route('instance', {
      path: ':instance_id'
    }, function () {
      this.route('dashboard', {
        path: '/'
      }, function () {});
      this.route('analytics', function () {
        this.route('detail', {
          path: ':id'
        });
      });
      this.route('conversion-attribution');
      this.route('enquiry-attribution', function () {
        this.route('date', function () {
          this.route('detail', {
            path: ':id'
          });
        });
      });
      this.route('auditlog');
      this.route('reports', function () {
        this.mount('@additive-apps/multimedia-engine', {
          as: 'additive-multimedia-engine',
          path: 'mm'
        });
        this.route('automated', function () {
          this.route('detail', {
            path: ':id'
          }, function () {
            this.route('edit', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
            this.route('slideshow');
          });
        });
        this.route('custom', function () {
          this.route('detail', {
            path: ':id'
          }, function () {
            this.route('edit', function () {
              this.mount('@additive-apps/multimedia-engine', {
                as: 'additive-multimedia-engine',
                path: 'mm'
              });
            });
            this.route('slideshow');
          });
        });
      });
      this.route('settings', function () {
        this.route('index', {
          path: '/'
        });
        this.route('snippet');
        this.route('reports');
        this.route('service-costs');
        this.route('budget');
      });
      this.route('stay-attribution', function () {
        this.route('enquiry', function () {
          this.route('detail', {
            path: ':id'
          });
        });
      });
    });
    this.route('page-not-found', {
      path: '*path'
    });
  });
});