define("additive-mi/routes/instance/reports/custom/detail/edit", ["exports", "@ember/routing/route", "@additive-apps/ui/mixins/ui-full-width-view"], function (_exports, _route, _uiFullWidthView) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line ember/no-mixins

  class InstanceReportsCustomDetailEditRoute extends _route.default.extend(_uiFullWidthView.default) {
    renderTemplate() {
      this.render('instance/reports/custom/detail/edit', {
        into: 'application'
      });
    }
    model() {
      return this.modelFor('instance.reports.custom.detail');
    }
  }
  _exports.default = InstanceReportsCustomDetailEditRoute;
});