define("additive-mi/mirage/factories/report", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    title: () => _faker.default.lorem.word(),
    description: () => _faker.default.lorem.words(10),
    createdAt: () => _faker.default.date.recent(),
    reportStart: () => _faker.default.date.recent(),
    reportEnd: () => _faker.default.date.recent(),
    type() {
      return _faker.default.random.arrayElement(['automated', 'custom']);
    },
    previewHtml: `
  <html>
    <head>
      <style>
        body {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          background: #00263E;
        }

        h1 {
          font-size: 40px;
          line-height: 48px;
          text-align: center;
          color: #fff;
        }
      </style>
    </head>
    <body>
      <h1>Slideshow</h1>
    </body>
  </html>`,
    active: () => _faker.default.random.boolean(),
    headerImage: null,
    slides: () => {
      return [{
        id: 'main',
        title: 'Kampagnen-Report',
        description: 'Dieser Slide zeigt die zusammengefassten KPI-Zahlen aller Online-Marketing-Aktivitäten.',
        totalValueAdded: {
          totalValueAdded: {
            id: _faker.default.random.number(),
            key: 'totalValueAdded',
            value: _faker.default.random.number(),
            customValue: null,
            label: 'Gesamtwertschöpung',
            format: 'currency_cent',
            isHidden: false
          },
          valueAdded: {
            id: _faker.default.random.number(),
            key: 'valueAdded',
            value: _faker.default.random.number(),
            customValue: null,
            label: 'Wertschöpfung',
            format: 'currency_cent',
            isHidden: false
          },
          revenue: {
            id: _faker.default.random.number(),
            key: 'revenue',
            value: _faker.default.random.number(),
            customValue: null,
            label: 'Umsatz',
            format: 'currency_cent',
            isHidden: false
          }
        },
        roi: {
          roi: {
            value: _faker.default.random.number(),
            label: 'A+ROI',
            customValue: null,
            description: 'Die Lösung ist Online Marketing auf Basis von Datenanalyse und Marketing',
            customDescription: null,
            isHidden: false
          }
        },
        otherKpis: {
          firstColumn: [{
            value: _faker.default.random.number(),
            customValue: null,
            label: 'Leads',
            format: _faker.default.random.arrayElement(['percentage', 'currency_cent', 'count']),
            isHidden: false,
            isCustom: false,
            position: 0
          }, {
            value: _faker.default.random.number(),
            customValue: null,
            label: 'Retention Rate',
            format: _faker.default.random.arrayElement(['percentage', 'currency_cent', 'count']),
            isHidden: false,
            isCustom: false,
            position: 1
          }],
          secondColumn: [{
            value: _faker.default.random.number(),
            customValue: null,
            label: 'Leads',
            format: _faker.default.random.arrayElement(['percentage', 'currency_cent', 'count']),
            isHidden: false,
            isCustom: false,
            position: 0
          }, {
            value: _faker.default.random.number(),
            customValue: null,
            label: 'Retention Rate',
            format: _faker.default.random.arrayElement(['percentage', 'currency_cent', 'count']),
            isHidden: true,
            isCustom: false,
            position: 1
          }],
          thirdColumn: [{
            value: _faker.default.random.number(),
            customValue: null,
            label: 'Leads',
            format: _faker.default.random.arrayElement(['percentage', 'currency_cent', 'count']),
            isHidden: true,
            isCustom: false,
            position: 0
          }, {
            value: _faker.default.random.number(),
            customValue: null,
            label: 'Retention Rate',
            format: _faker.default.random.arrayElement(['percentage', 'currency_cent', 'count']),
            isHidden: false,
            isCustom: false,
            position: 1
          }]
        }
      }, {
        id: 'newsletter-marketing',
        title: 'Newsletter Marketing',
        description: 'Dieser Slide zeigt die Ergebnisse und KPI-Zahlen der Newsletter-Kampagnen.',
        totalValueAdded: {
          totalValueAdded: {
            id: _faker.default.random.number(),
            key: 'totalValueAdded',
            value: _faker.default.random.number(),
            customValue: null,
            label: 'Gesamtwertschöpung',
            format: 'currency_cent',
            isHidden: false
          },
          valueAdded: {
            id: _faker.default.random.number(),
            key: 'valueAdded',
            value: _faker.default.random.number(),
            customValue: null,
            label: 'Wertschöpfung',
            format: 'currency_cent',
            isHidden: false
          },
          revenue: {
            id: _faker.default.random.number(),
            key: 'revenue',
            value: _faker.default.random.number(),
            customValue: null,
            label: 'Umsatz',
            format: 'currency_cent',
            isHidden: false
          }
        },
        otherKpis: {
          firstColumn: [{
            id: 1132,
            key: 'new-customers',
            value: _faker.default.random.number(),
            customValue: null,
            format: 'count',
            label: 'Neue',
            description: null,
            isHidden: false,
            position: 3,
            isCustom: false
          }, {
            id: 1133,
            key: 'returning-customers',
            value: _faker.default.random.number(),
            customValue: null,
            format: 'count',
            label: 'Wiederkehrende',
            description: null,
            isHidden: false,
            position: 6,
            isCustom: false
          }, {
            id: 1134,
            key: 'booking-conversions',
            value: _faker.default.random.number(),
            customValue: null,
            format: 'count',
            label: 'Direktbuchungen',
            description: null,
            isHidden: true,
            position: 7,
            isCustom: false
          }, {
            id: 1131,
            key: 'reservations',
            value: _faker.default.random.number(),
            customValue: null,
            format: 'count',
            label: 'Reservierungen',
            description: null,
            isHidden: true,
            position: 8,
            isCustom: false
          }],
          secondColumn: [{
            id: 1140,
            key: 'total-emails-sent',
            value: _faker.default.random.number(),
            customValue: null,
            format: 'count',
            label: 'Gesendete E-Mails',
            description: null,
            isHidden: false,
            position: 4,
            isCustom: false
          }, {
            id: 1138,
            key: 'open-rate',
            value: _faker.default.random.number(),
            customValue: null,
            format: 'percentage',
            label: 'Öffnungsrate',
            description: null,
            isHidden: true,
            position: 5,
            isCustom: false
          }, {
            id: 1139,
            key: 'click-to-open-rate',
            value: _faker.default.random.number(),
            customValue: null,
            format: 'percentage',
            label: 'Klick zu Öffnungsrate',
            description: null,
            isHidden: true,
            position: 6,
            isCustom: false
          }, {
            id: 1135,
            key: 'requests',
            value: _faker.default.random.number(),
            customValue: null,
            format: 'count',
            label: 'Anfragen',
            description: null,
            isHidden: true,
            position: 7,
            isCustom: false
          }, {
            id: 1136,
            key: 'sessions',
            value: _faker.default.random.number(),
            customValue: null,
            format: 'count',
            label: 'Sitzungen',
            description: null,
            isHidden: true,
            position: 8,
            isCustom: false
          }, {
            id: 1137,
            key: 'newsletter-openings',
            value: _faker.default.random.number(),
            customValue: null,
            format: 'count',
            label: 'Newsletter Öffnungen',
            description: null,
            isHidden: true,
            position: 9,
            isCustom: false
          }]
        }
      }];
    },
    notices: () => {
      return [{
        id: _faker.default.random.number(),
        key: 'description_not_updated',
        severity: 'warning',
        affectedMonths: null,
        message: 'Since the report has been recalculated, it is possible that the description is no longer up to date. Please review the description and save the report again.',
        action: null
      }];
    },
    afterCreate(report, server) {
      const reportConfiguration = server.schema.reportConfigurations.first();
      const today = new Date();
      let currentServiceCost = null;
      server.schema.serviceCosts.all().sort((a, b) => {
        return b.startsAt < a.startsAt ? 1 : -1;
      }).models.forEach(serviceCost => {
        if (serviceCost.startsAt <= today) {
          if (!currentServiceCost) {
            currentServiceCost = serviceCost.value;
          } else if (currentServiceCost.startsAt < serviceCost.startsAt) {
            currentServiceCost = serviceCost.value;
          }
        }
      });
      let currentBudget = null;
      server.schema.budgets.all().sort((a, b) => {
        return b.startsAt < a.startsAt ? 1 : -1;
      }).models.forEach(budget => {
        if (budget.startsAt <= today) {
          if (!currentBudget) {
            currentBudget = budget.value;
          } else if (currentBudget.startsAt < budget.startsAt) {
            currentBudget = budget.value;
          }
        }
      });
      report.update({
        settings: {
          calculateNewsletterOpenings: reportConfiguration.calculateNewsletterOpenings,
          calculateRoi: reportConfiguration.calculateRoi,
          cancellationsAsReservations: reportConfiguration.cancellationsAsReservations,
          showKpiInfo: reportConfiguration.showKpiInfo,
          showDescriptionSlide: reportConfiguration.showDescriptionSlide,
          showCharts: reportConfiguration.showCharts,
          showHistoricalDataCharts: reportConfiguration.showHistoricalDataCharts,
          showNewsletterMarketingSlide: reportConfiguration.showNewsletterMarketingSlide,
          optionsAsReservations: reportConfiguration.optionsAsReservations,
          sessionBasis: {
            label: reportConfiguration.sessionBasis.value === 'cookieless' ? 'Cookieless' : 'Cookies',
            value: reportConfiguration.sessionBasis.value
          },
          useReservationData: reportConfiguration.useReservationData,
          useEmpiricalDataToCalculateRevenue: reportConfiguration.useEmpiricalDataToCalculateRevenue,
          calculateConversionValuesAsRevenue: reportConfiguration.calculateConversionValuesAsRevenue,
          averageReservationRevenue: reportConfiguration.averageReservationRevenue,
          conversionRateRequestsToReservations: reportConfiguration.conversionRateRequestsToReservations,
          includeHosts: reportConfiguration.includeHosts,
          isMonthlyComparisonTableAllowed: reportConfiguration.isMonthlyComparisonTableAllowed
        },
        appliedSettings: {
          monthlyFees: {
            byMonth: [{
              month: report.reportStart,
              serviceCosts: currentServiceCost,
              budgets: currentBudget,
              surcharge: 123
            }],
            total: {
              serviceCosts: currentServiceCost,
              budgets: currentBudget,
              surcharge: 123
            }
          }
        }
      });
      if (_faker.default.random.boolean()) {
        const image = server.create('image');
        report.update({
          headerImage: image.toJSON()
        });
      }
      if (report.type === 'automated') {
        const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        report.update({
          title: `${monthNames[report.createdAt.getMonth()]} ${report.createdAt.getFullYear()}`
        });
      }
    }
  });
});