define("additive-mi/templates/instance/stay-attribution/enquiry/detail", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "yh/cUBAB",
    "block": "[[[8,[39,0],[[16,\"data-test-stay-attribution-enquiry-detail\",true]],[[\"@apiRoute\",\"@baseRoute\",\"@detailId\",\"@organizationSlug\",\"@dateRange\",\"@title\"],[\"enquiry-attribution/stay\",\"instance.stay-attribution.enquiry\",[30,0,[\"detailId\"]],[30,0,[\"organizationId\"]],[28,[37,1],null,[[\"start\",\"end\"],[[30,0,[\"start\"]],[30,0,[\"end\"]]]]],[28,[37,2],[\"stayAttribution.title\"],null]]],null]],[],false,[\"ami-attribution-table/detail-dialog\",\"hash\",\"t\"]]",
    "moduleName": "additive-mi/templates/instance/stay-attribution/enquiry/detail.hbs",
    "isStrictMode": false
  });
});