define("additive-mi/mirage/routes/reservations", ["exports", "date-fns", "faker"], function (_exports, _dateFns, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ReservationsRequest = void 0;
  const ReservationsRequest = self => {
    self.get('/reservations', (_, req) => {
      const {
        start,
        end
      } = req.queryParams;
      return {
        reservations: {
          type: 'compare',
          change: 59.35,
          current: {
            value: start ? Math.floor(Math.random() * 3000) : 335074.68,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) : 2019
          },
          compare: {
            value: 210271.76,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) - 1 : 2019
          }
        },
        newGuests: {
          type: 'compare',
          change: 0,
          current: {
            value: start ? Math.floor(Math.random() * 200) : 90,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) : 2019
          },
          compare: {
            value: 100,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) - 1 : 2019
          }
        },
        returningGuests: {
          type: 'compare',
          change: 32.63,
          current: {
            value: start ? Math.floor(Math.random() * 6000) : 37000,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) : 2019
          },
          compare: {
            value: 6985,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) - 1 : 2019
          }
        }
      };
    });
    self.get('/reservations-table', (_, req) => {
      const {
        start,
        end
      } = req.queryParams;
      const data = [];
      for (let i = 0; i < 36; i++) {
        data.push([{
          value: _faker.default.name.findName(),
          link: 'https://google.com',
          format: 'custom',
          customKey: 'name'
        }, 'returning', 'Reservation', {
          value: _faker.default.date.past(),
          format: 'date'
        }, {
          value: _faker.default.random.number(),
          format: 'currency_cent'
        }, {
          value: {
            start: _faker.default.date.between(start, end),
            end: _faker.default.date.between(start, end)
          },
          format: 'custom',
          customKey: 'range'
        }, '3 Erw. 2 Kinder', 'organic_search', 'Geburtstagsgutschein von Freunden']);
      }
      return {
        labels: ['Confirmed Guest', 'Type', 'Res. Status', 'Res. Date', 'Res. Revenue', 'Stay', 'Group', 'Medium', 'Source', 'Campaign'],
        data
      };
    });
  };
  _exports.ReservationsRequest = ReservationsRequest;
});