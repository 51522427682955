define("additive-mi/components/ami-reports/error-page", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class="ami-reports-error-page flex flex-column justify-between relative w-100 h-100 pa3 bg-primary white ohidden"
  >
    <div class="ami-reports-error-page__background absolute br-100"></div>
    <div class="ami-reports-error-page__background absolute br-100"></div>
    <div class="ami-reports-error-page__background absolute br-100"></div>
  
    <div class="flex justify-between">
      {{svg-jar "additive-full"}}
  
      <UiSelect
        @options={{this._localeOptions}}
        @selected={{get (array-find this._currentLocale this._localeOptions) "label"}}
        @isPlain={{true}}
        @theme="dark"
        @onChange={{this.onChangeLocale}}
        as |option item|
      >
        <option.item @value={{item.value}}>
          {{item.label}}
        </option.item>
      </UiSelect>
    </div>
    <div
      class="ami-reports-error-page__title font-xl font-medium flex items-center justify-center flex-grow-1"
    >
      {{t "components.amiReports.errorPage.error"}}
    </div>
  </div>
  */
  {
    "id": "6NGF+5j+",
    "block": "[[[10,0],[14,0,\"ami-reports-error-page flex flex-column justify-between relative w-100 h-100 pa3 bg-primary white ohidden\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"ami-reports-error-page__background absolute br-100\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"ami-reports-error-page__background absolute br-100\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"ami-reports-error-page__background absolute br-100\"],[12],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"flex justify-between\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"additive-full\"],null]],[1,\"\\n\\n    \"],[8,[39,1],null,[[\"@options\",\"@selected\",\"@isPlain\",\"@theme\",\"@onChange\"],[[30,0,[\"_localeOptions\"]],[28,[37,2],[[28,[37,3],[[30,0,[\"_currentLocale\"]],[30,0,[\"_localeOptions\"]]],null],\"label\"],null],true,\"dark\",[30,0,[\"onChangeLocale\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,1,[\"item\"]],null,[[\"@value\"],[[30,2,[\"value\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,2,[\"label\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[1,2]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"ami-reports-error-page__title font-xl font-medium flex items-center justify-center flex-grow-1\"],[12],[1,\"\\n    \"],[1,[28,[35,4],[\"components.amiReports.errorPage.error\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"option\",\"item\"],false,[\"svg-jar\",\"ui-select\",\"get\",\"array-find\",\"t\"]]",
    "moduleName": "additive-mi/components/ami-reports/error-page.hbs",
    "isStrictMode": false
  });
  let AmiReportsErrorPage = _exports.default = (_class = class AmiReportsErrorPage extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "uiLocale", _descriptor2, this);
      _initializerDefineProperty(this, "_currentLocale", _descriptor3, this);
      _defineProperty(this, "_localeOptions", ['de', 'en'].map(lang => {
        return {
          label: this.intl.t(`global.languages.${lang}`, {
            locale: lang
          }),
          value: lang
        };
      }));
      const browserLocale = (navigator.language || navigator.userLanguage) === 'de' ? 'de' : 'en';
      this.onChangeLocale(browserLocale);
    }
    onChangeLocale(lang) {
      this._currentLocale = lang;
      this.uiLocale.setLocale(this._currentLocale);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "uiLocale", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_currentLocale", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onChangeLocale", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeLocale"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AmiReportsErrorPage);
});