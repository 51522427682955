define("additive-mi/templates/instance/reports/custom/detail", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "WpAZ95Ip",
    "block": "[[[8,[39,0],null,[[\"@report\",\"@onClose\",\"@isCustom\"],[[30,0,[\"model\"]],[30,0,[\"closePreview\"]],true]],null]],[],false,[\"ami-reports/preview\"]]",
    "moduleName": "additive-mi/templates/instance/reports/custom/detail.hbs",
    "isStrictMode": false
  });
});