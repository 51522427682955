define("additive-mi/mirage/factories/enquiry-attribution-detail-table", ["exports", "ember-cli-mirage", "date-fns", "faker", "additive-mi/utils/currency-serialization"], function (_exports, _emberCliMirage, _dateFns, _faker, _currencySerialization) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberCliMirage.Factory.extend({
    labels() {
      return ['Kanal', 'Anfragen', 'Reservierungen', 'Neue Gäste', 'Ratio', 'Ankünfte', 'Nächtigungen', 'Res. Umsatz', '∅ AD', '∅ GG', '∅ BV', '∅ RU'];
    },
    data() {
      let rows = [];
      const today = new Date();
      const start = new Date((0, _dateFns.getYear)(today), 0, 1);
      /* Build row for each label */
      for (let index = 0; index < 12; index++) {
        let row = [];
        // label (Month)
        row.push((0, _dateFns.format)((0, _dateFns.addMonths)(new Date(start.toString()), index), 'MMM yyyy'));
        for (let index = 0; index < 11; index++) {
          row.push(_faker.default.random.number(1000));
        }

        /* Replace ratio data with object as it has a percentage value as well*/
        row[4] = {
          value: `${row[4]}:1`,
          percentage: parseFloat(Math.random() * 10).toFixed(2)
        };

        /* Currency values */
        row[7] = {
          format: 'currency_cent',
          value: (0, _currencySerialization.amountToCents)(row[7])
        };
        row[11] = {
          format: 'currency_cent',
          value: (0, _currencySerialization.amountToCents)(row[11])
        };
        rows.push(row);
      }
      return rows;
    }
  });
});