define("additive-mi/mirage/routes/index", ["exports", "additive-mi/mirage/routes/conversions", "additive-mi/mirage/routes/analytics", "additive-mi/mirage/routes/analytics/detail", "additive-mi/mirage/routes/conversion-attribution", "additive-mi/mirage/routes/report", "additive-mi/mirage/routes/enquiry-attribution", "additive-mi/mirage/routes/enquiry-attribution/detail", "additive-mi/mirage/routes/filters", "additive-mi/mirage/routes/key-indicators", "additive-mi/mirage/routes/performance-indicators", "additive-mi/mirage/routes/reservations", "additive-mi/mirage/routes/alerts", "additive-mi/mirage/routes/public-routes"], function (_exports, _conversions, _analytics, _detail, _conversionAttribution, _report, _enquiryAttribution, _detail2, _filters, _keyIndicators, _performanceIndicators, _reservations, _alerts, _publicRoutes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "AlertsRequest", {
    enumerable: true,
    get: function () {
      return _alerts.AlertsRequest;
    }
  });
  Object.defineProperty(_exports, "AnalyticsDetailRequest", {
    enumerable: true,
    get: function () {
      return _detail.AnalyticsDetailRequest;
    }
  });
  Object.defineProperty(_exports, "AnalyticsRequest", {
    enumerable: true,
    get: function () {
      return _analytics.AnalyticsRequest;
    }
  });
  Object.defineProperty(_exports, "ConversionAttributionRequest", {
    enumerable: true,
    get: function () {
      return _conversionAttribution.ConversionAttributionRequest;
    }
  });
  Object.defineProperty(_exports, "ConversionsRequest", {
    enumerable: true,
    get: function () {
      return _conversions.ConversionsRequest;
    }
  });
  Object.defineProperty(_exports, "EnquiryAttributionDetailRequest", {
    enumerable: true,
    get: function () {
      return _detail2.EnquiryAttributionDetailRequest;
    }
  });
  Object.defineProperty(_exports, "EnquiryAttributionRequest", {
    enumerable: true,
    get: function () {
      return _enquiryAttribution.EnquiryAttributionRequest;
    }
  });
  Object.defineProperty(_exports, "FiltersRequest", {
    enumerable: true,
    get: function () {
      return _filters.FiltersRequest;
    }
  });
  Object.defineProperty(_exports, "KeyIndicatorsRequest", {
    enumerable: true,
    get: function () {
      return _keyIndicators.KeyIndicatorsRequest;
    }
  });
  Object.defineProperty(_exports, "PerformanceIndicatorsRequest", {
    enumerable: true,
    get: function () {
      return _performanceIndicators.PerformanceIndicatorsRequest;
    }
  });
  Object.defineProperty(_exports, "PublicRoutes", {
    enumerable: true,
    get: function () {
      return _publicRoutes.PublicRoutes;
    }
  });
  Object.defineProperty(_exports, "ReportsRequest", {
    enumerable: true,
    get: function () {
      return _report.ReportsRequest;
    }
  });
  Object.defineProperty(_exports, "ReservationsRequest", {
    enumerable: true,
    get: function () {
      return _reservations.ReservationsRequest;
    }
  });
});