define("additive-mi/mirage/routes/conversions", ["exports", "date-fns"], function (_exports, _dateFns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ConversionsRequest = void 0;
  const ConversionsRequest = self => {
    self.get('/conversions', (_, req) => {
      const {
        start,
        end
      } = req.queryParams;
      return {
        conversions: {
          type: 'compare',
          change: 59.35,
          current: {
            value: start ? Math.random() * 300 : 335074.68,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) : 2019
          },
          compare: {
            value: 210271.76,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) - 1 : 2019
          }
        },
        enquiries: {
          type: 'compare',
          change: 0,
          current: {
            value: start ? Math.floor(Math.random() * 200) : 90,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) : 2019
          },
          compare: {
            value: 100,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) - 1 : 2019
          }
        },
        leads: {
          type: 'compare',
          change: 4.19,
          current: {
            value: start ? Math.floor(Math.random() * 2000) : 1200,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) : 2019
          },
          compare: {
            value: 1274,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) - 1 : 2019
          }
        },
        vouchers: {
          type: 'compare',
          change: 32.63,
          current: {
            value: start ? Math.floor(Math.random() * 6000) : 37000,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) : 2019
          },
          compare: {
            value: 6985,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) - 1 : 2019
          }
        },
        bookings: {
          type: 'compare',
          change: 32.63,
          current: {
            value: start ? Math.floor(Math.random() * 6000) : 37000,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) : 2019
          },
          compare: {
            value: 6985,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) - 1 : 2019
          }
        },
        others: {
          type: 'compare',
          change: 32.63,
          current: {
            value: start ? Math.floor(Math.random() * 6000) : 37000,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) : 2019
          },
          compare: {
            value: 6985,
            type: 'yearly',
            date: end ? (0, _dateFns.getYear)(end) - 1 : 2019
          }
        }
      };
    });
  };
  _exports.ConversionsRequest = ConversionsRequest;
});